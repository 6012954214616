import React, { useState } from "react"
import Modal from "react-bootstrap/Modal"

import Button from "../../Button"
import UCheckbox from "../../UCheckbox"

import "../style.scss"
import { Stack } from "@mui/material"
import URadio from "../../URadio"

const JobRecommendationConsentModal = ({
  showConsentModal,
  setShowConsentModal,
  showSpinner,
  onClick,
  consentValue,
  ...props
}) => {
  const [jobConsent, setJobConsent] = useState(consentValue)

  const handleOnclick = () => {
    onClick(jobConsent)
  }

  return (
    <>
      <Modal
        show={showConsentModal}
        setShow={setShowConsentModal}
        centered
        className="auth-modal consent-modal"
      >
        <Modal.Body>
          <div className="auth-modal-body-wrapper consent-modal-body-wrapper">
            <div className="main-title">Job Recommendation Consent</div>
            <div className="text-center">
              I would like Experis to use my profile data to provide me with
              tailored job recommendations.
            </div>
            <Stack
              direction="row"
              flexWrap={"wrap"}
              spacing={1}
              overflow="auto"
              useFlexGap
              justifyContent="center"
            >
              <URadio
                label="Yes"
                value="1"
                name="job-consent"
                onChange={e => setJobConsent(e.target.value)}
                selectedValue={jobConsent}
              />
              <URadio
                label="No"
                value="2"
                name="job-consent"
                onChange={e => setJobConsent(e.target.value)}
                selectedValue={jobConsent}
              />
            </Stack>
            <div className="button-wrapper flex-justify-center">
              <Button
                title="Save"
                className={jobConsent ? "uiba-btn" : "disabled-btn"}
                onClick={handleOnclick}
                disabled={jobConsent ? false : true}
                showSpinner={showSpinner}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default JobRecommendationConsentModal
