import React, { useState, useEffect } from "react"

//Components
import Button from "../../../components/Button"
import DeleteAccountModal from "../../../components/CandidateModals/DeleteAccountModal"
import PasswordSuccessModal from "../../../components/CandidateModals/PasswordSuccessModal"
import PasswordInput from "../../../components/PasswordInput"
import StrengthMeter from "../../../components/StrengthMeter"

// Validation
import { useForm } from "../../../hooks"

// Redux
import { connect } from "react-redux"
import {
  putChangePasswordRequest,
  putChangePasswordRequestFailure,
  putChangePasswordRequestSuccess
} from "./redux/action"

// Style
import "./style.scss"

const ChangePassword = props => {
  const {
    putChangePasswordRequest,
    putChangePasswordRequestSuccess,
    putChangePasswordRequestFailure,
    changePasswordInfo,
    requesting,
    error
  } = props

  const [showLogoutModal, setShowLogoutModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [passMatched, setPassMatched] = useState(false)
  const [strength, setStrength] = useState("")
  const [oldPasswordError, setOldPassword] = useState(false)

  useEffect(() => {
    putChangePasswordRequestFailure(false)
  }, [])

  useEffect(() => {
    if (changePasswordInfo) {
      setShowSuccessModal(true)
      setState(stateSchema)
      initValue({
        password: ""
      })
    }
  }, [changePasswordInfo])

  const stateSchema = {
    old_password: {
      value: "",
      error: ""
    },
    new_password1: {
      value: "",
      error: ""
    },
    new_password2: {
      value: "",
      error: ""
    }
  }

  const validationStateSchema = {
    old_password: {
      required: true
    },
    new_password1: {
      required: true
    },
    new_password2: {
      required: true
    }
  }

  const { state, handleOnChange, setState } = useForm(
    stateSchema,
    validationStateSchema
  )

  const onSubmit = async () => {
    const data = {
      old_password: state.old_password.value,
      new_password1: state.new_password1.value,
      new_password2: state.new_password2.value
    }
    // putChangePasswordRequest(data)
    if (data.new_password1 === state.new_password2.value) {
      await putChangePasswordRequest(data)
    } else if (!data.new_password1) {
      setPassMatched("Please enter password")
    } else if (state.new_password2.value) {
      setPassMatched("The password and confirmation password do not match.")
    }
  }

  // Confirm Match Password
  const matchPassword = value => {
    if (!state.new_password1.value) {
      setPassMatched(false)
    } else if (value === state.new_password1.value) {
      setPassMatched(false)
    } else {
      setPassMatched("The password and confirmation password do not match.")
    }
  }

  // Strenght Checker Progress Bar

  const [pwdInput, initValue] = useState({
    password: ""
  })
  const [isError, setError] = useState(null)

  const [isStrong, initRobustPassword] = useState(null)
  const initPwdInput = async childData => {
    initRobustPassword(childData)
  }

  const validatePassword = value => {
    let caps, small, num, specialSymbol
    initValue({
      ...pwdInput,
      password: value
    })
    if (value?.length < 8) {
      setError(
        "Password should contain minimum 8 characters, with one UPPERCASE, lowercase, number and special character: @$! % * ? &"
      )
      return
    } else if (value?.length > 24) {
      setError(
        "Password should contain maximimum 24 characters, with one UPPERCASE, lowercase, number and special character: @$! % * ? &"
      )
      return
    } else {
      caps = (value.match(/[A-Z]/g) || []).length
      small = (value.match(/[a-z]/g) || []).length
      num = (value.match(/[0-9]/g) || []).length
      specialSymbol = (value.match(/\W/g) || []).length
      if (caps < 1) {
        setError("Must add one UPPERCASE letter")
        return
      } else if (small < 1) {
        setError("Must add one lowercase letter")
        return
      } else if (num < 1) {
        setError("Must add one number")
        return
      } else if (specialSymbol < 1) {
        setError("Must add one special symbol: @ $ ! % * ? &")
        return
      } else {
        setError(false)
      }
    }
  }
  const validateOldPassword = value => {
    if (!value) {
      setOldPassword("Please provide Old Password")
    }
  }

  const handleInputChange = field => val => {
    handleOnChange(field, val.target.value)
    if (field === "old_password") {
      validateOldPassword(val.target.value)
    }
    if (field === "new_password1") {
      validatePassword(val.target.value)
      if (state.new_password2.value?.length) {
        matchPassword(state.new_password2.value)
      }
    }
    if (field === "new_password2") {
      matchPassword(val.target.value)
    }
  }

  return (
    <>
      <section className="change-password-section w-100">
        <div className="input-wrapper">
          <div className="heading">Change Password</div>
          <PasswordInput
            focusedLabel="Current Password"
            placeholder="Current Password"
            value={state.old_password.value}
            onChange={handleInputChange("old_password")}
            className={
              error?.old_password &&
              error?.old_password[0] === "This field may not be blank."
                ? "error-field-wrapper"
                : ""
            }
            onFocus={() => {
              putChangePasswordRequestFailure(false)
            }}
          />
          <div
            className={`error-message ${
              error?.old_password || state.old_password.error ? "opacity-1" : ""
            }`}
          >
            {error?.old_password || state.old_password.error}
          </div>
          <PasswordInput
            focusedLabel="New Password"
            placeholder="New Password"
            value={state.new_password1.value}
            onChange={handleInputChange("new_password1")}
            className={
              error?.new_password1 &&
              error?.new_password1[0] === "This field may not be blank."
                ? "error-field-wrapper"
                : ""
            }
            onFocus={() => {
              putChangePasswordRequestFailure(false)
              setPassMatched(false)
            }}
          />
          <StrengthMeter password={pwdInput.password} actions={initPwdInput} />
          <div
            className={`error-message ${
              error?.new_password1 || state.new_password1.error || isError
                ? "opacity-1"
                : ""
            }`}
          >
            {error?.new_password1 || state.new_password1.error || isError}
          </div>

          <PasswordInput
            focusedLabel="Confirm Password"
            placeholder="Confirm Password"
            value={state.new_password2.value}
            onChange={handleInputChange("new_password2")}
            onFocus={() => {
              putChangePasswordRequestFailure(false)
              // setPassMatched(false)
            }}
          />
          <div className={`error-message ${passMatched ? "opacity-1" : ""}`}>
            {passMatched}
          </div>
          <div className="button-wrapper flex-justify-center">
            <Button
              title="Change Password"
              className={
                !state.old_password.value ||
                !state.new_password1.value ||
                passMatched ||
                !state.new_password2.value ||
                isError
                  ? "disabled-btn"
                  : ""
              }
              onClick={onSubmit}
              // onClick={() => setShowSuccessModal(true)}
              showSpinner={requesting}
            />
          </div>
          <div
            className="delete-account"
            onClick={() => setShowLogoutModal(true)}
          >
            Close my account
          </div>
        </div>
      </section>
      <DeleteAccountModal
        showDeleteAccountModal={showLogoutModal}
        setShowDeleteAccountModal={setShowLogoutModal}
      />
      <PasswordSuccessModal
        showSuccessModal={showSuccessModal}
        setShowSuccessModal={setShowSuccessModal}
      />
    </>
  )
}

const mapStateToProps = state => ({
  changePasswordInfo: state.changePassword.changePasswordInfo,
  requesting: state.changePassword.requesting,
  error: state.changePassword.error
})

const mapDispatchToProps = dispatch => ({
  putChangePasswordRequest: data => dispatch(putChangePasswordRequest(data)),
  putChangePasswordRequestFailure: data =>
    dispatch(putChangePasswordRequestFailure(data)),
  putChangePasswordRequestSuccess: data =>
    dispatch(putChangePasswordRequestSuccess(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)
