import React, { useState, useEffect, useContext } from "react"


// Theme Context
import { ThemeContext } from "../../../contexts/theme-context"

// Styles
import "./style.scss"

const Landing = () => {


  const { theme, setTheme } = useContext(ThemeContext);

  const handleThemeChange = () => {
    const isCurrentDark = theme === 'dark';
    setTheme(isCurrentDark ? 'light' : 'dark');
    localStorage.setItem('default-theme', isCurrentDark ? 'light' : 'dark');
  };

  return (
    <>
      {/* <input
        className="toggle-btn__input"
        type="checkbox"
        name="checkbox"
        onChange={handleThemeChange}
        checked={theme === 'light'}
      />
      <button type="button" className={`toggle-btn__input-label`} onClick={handleThemeChange}></button> */}
      Login Successful
    </>
  )
}

export default Landing
