import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import dayjs from "dayjs"
import { connect } from "react-redux"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"

import SkillCard from "../../../components/ProfileEditedCards/SkillCard"
import Image from "../../../components/Image"
import { Images } from "../../../theme/Images"
import {
  formatSkills,
  getCurrencySymbol,
  getFormattedLocation,
  isCandidate,
  getEmploymentTypeLabel,
  getKeyToLabelData
} from "../../../utils/helper"
import { getJobDataByIdRequest, getSkillsScoreRequest } from "./redux/action"
import "./style.scss"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined"
import NotInterestedIcon from "@mui/icons-material/NotInterested"
import {
  closeJobRequest,
  deleteJobRequest
} from "../JobsList/redux/action"
import DeleteConfirmModal from "../../../components/Modals/DeleteConfirmModal"
import { DEFAULT_DATE_FORMAT } from "../../../utils/constants"
import { useMetaData } from "../../../hooks"
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

const JobDetails = (props) => {
  const {
    getSkillsScore,
    skillsScore,
    userType,
    closeJobDispatch,
    deleteJobDispatch,
    closeRequesting,
    deleteRequesting,
    getJobData,
    jobDetails
  } = props
  const history = useHistory()
  const [skillsList, setSkillsList] = useState([])
  const { location } = history
  const [jobInformation, setJobInformation] = useState({})

  const candidateSkills = location?.state?.candidateSkills
  const isCandidateView = isCandidate(userType)


  const { jobId } = useParams()
  useEffect(() => {
    const shouldResetJobInformation = (jobId !== jobInformation?._id)
    if (shouldResetJobInformation) {
      setJobInformation({})
    }
    if (jobId) {
      const job_data = getJobData({jobId})
      setJobInformation(jobDetails)
    }
  }, [jobId, location?.state])

  useEffect(() => {
    if (jobDetails?._id && jobDetails?._id === jobId) {
      setJobInformation(jobDetails)
    } else {
      setJobInformation({})
    }
  }, [jobDetails])

  const [deleteModal, setDeleteModal] = useState({
    show: false,
    action: ""
  })
  const [jobToDelete, setJobToDelete] = useState({})

  const goBack = () => {
    history.goBack()
  }

  useEffect(() => {
    jobInformation?.skills?.length &&
      getSkillsScore({ skills: jobInformation?.skills })
  }, [jobInformation?.skills, getSkillsScore])

  useEffect(() => {
    skillsScore?.length && setSkillsList(skillsScore)
  }, [skillsScore])


  const closeDeleteModal = () => {
    setDeleteModal({ show: false, action: "" })
    setJobToDelete({})
  }

  const handleJobEdit = jobData => () => {
    history.push(`/${userType}/jobs/edit/${jobData._id}`, { selectedJobDetails: jobData })
  }

  const handleJobClose = jobData => () => {
    setDeleteModal({ show: true, action: "close" })
    setJobToDelete(jobData)
  }

  const handleJobDelete = jobData => () => {
    setDeleteModal({ show: true, action: "delete" })
    setJobToDelete(jobData)
  }

  const closeJob = () => {
    deleteModal?.action === "delete"
      ? deleteJobDispatch(jobToDelete)
      : closeJobDispatch(jobToDelete)
    history.goBack()
  }

  const { jobCategoryList } = useMetaData()
  const jobCategoryListKeyToLabel = getKeyToLabelData(jobCategoryList)

  return (
    <>
      {jobInformation?.id && (
        <>
          <div className="job-details-container">
          <div className="header">
            <div className="back-option" onClick={goBack}>
              <ChevronLeftIcon />
              &nbsp;Back
            </div>
            <div className="heading-div flex-justify-between">
              <div className="job-title-with-action">
                <h2>{jobInformation?.title}</h2>
                {(userType === 'admin' || userType === 'recruiter') && (
                    <div className="action-icons">
                    <EditOutlinedIcon
                      onClick={handleJobEdit(jobInformation)}
                      className="action-edit"
                    />
                    <NotInterestedIcon
                      onClick={handleJobClose(jobInformation)}
                      className="action-delete"
                    />
                    {userType === 'admin' && (
                      <DeleteOutlinedIcon
                        onClick={handleJobDelete(jobInformation)}
                        className="action-delete"
                      />
                    )}
                  </div>
                )}
              </div>

              <div className="published-by">
                {!isCandidateView && (
                  <div className="company">
                    <Image src={Images.company_dark} />
                    <div className="name">{jobInformation?.client_company}</div>
                  </div>
                )}
                <div className="location">
                  <Image src={Images.location_dark} />
                  <div className="location-name">
                    {getFormattedLocation(jobInformation?.locations)} (
                    {jobInformation?.job_type})
                  </div>
                </div>
                <div className="posted-date">
                  - Posted{" "}
                  {dayjs(jobInformation?.job_created_date)
                    .endOf("day")
                    .fromNow()}
                </div>
              </div>
            </div>
          </div>
          <div className="job-details-section">
            <div className="job-details-section-data card">
              <div className="card-section">
                <div className="section-header">Job details</div>
                {!isCandidateView && (
                  <div className="details-section">
                    <div className="section-column">
                      <div className="head">Start Date</div>
                      {dayjs(jobInformation?.start_date).isValid() ? dayjs(jobInformation?.start_date).format(DEFAULT_DATE_FORMAT) : 'N/A'}
                    </div>
                    <div className="section-column">
                      <div className="head">End Date</div>
                      {dayjs(jobInformation?.end_date).isValid() ? dayjs(jobInformation?.end_date).format(DEFAULT_DATE_FORMAT) : 'N/A'}
                    </div>
                  </div>
                )}
                <div className="description">
                  <div className="head">Description</div>
                  <div
                    className="job-description"
                    dangerouslySetInnerHTML={{
                      __html: jobInformation?.description
                    }}
                  />
                </div>
                {!isCandidateView && (
                  <>
                    <div className="details-section">
                      <div className="section-column">
                        <div className="head">Assignee</div>
                        {jobInformation?.experisContactName}
                      </div>
                      <div className="section-column">
                      <div className="head">Assignee Email</div>
                        {jobInformation?.assignees?? jobInformation?.assigneeContactEmail}
                      </div>
                    </div>
                    <div className="details-section">
                      <div className="section-column">
                        <div className="head">Owner</div>
                        {jobInformation?.owner}
                      </div>
                    </div>
                    <div className="details-section">
                      <div className="section-column">
                        <div className="head">Job Category</div>
                        {jobCategoryListKeyToLabel[jobInformation?.job_category]?? ''}
                      </div>
                      <div className="section-column">
                        <div className="head">Experience Level</div>
                        {jobInformation?.experience_level}
                      </div>
                    </div>
                    <div className="details-section">
                      <div className="section-column">
                        <div className="head">Employment Type</div>
                        {getEmploymentTypeLabel(jobInformation?.employment_type)}
                      </div>
                      <div className="section-column">
                        <div className="head">Fillability:</div>
                        {jobInformation?.fillability}
                      </div>
                    </div>
                  </>
                )}
              </div>
              {!isCandidateView && (
                <div className="card-section">
                  <div className="section-header">Compensation</div>
                  <div className="details-section">
                    <div className="section-column">
                      <div className="head">Min Compensation</div>
                      {getCurrencySymbol(jobInformation?.currency)}{" "}
                      {jobInformation?.min_compensation}
                    </div>
                    <div className="section-column">
                      <div className="head">Max Compensation</div>
                      {getCurrencySymbol(jobInformation?.currency)}{" "}
                      {jobInformation?.max_compensation}
                    </div>
                  </div>
                  <div className="details-section">
                    <div className="section-column">
                      <div className="head">Period</div>
                      {jobInformation?.compensation_period}
                    </div>
                    <div className="section-column">
                      <div className="head">Currency</div>
                      {jobInformation?.currency}
                    </div>
                  </div>
                </div>
              )}
              <div className="card-section skill-wrapper">
                <div className="section-header">Skills</div>
                <SkillCard
                  isEdited={true}
                  getSkillInfo={
                    candidateSkills?.length
                      ? candidateSkills
                      : formatSkills(skillsList)
                  }
                  compareSkillsInfo={
                    candidateSkills?.length ? formatSkills(skillsList) : ""
                  }
                  isCandidate={false}
                  legends={
                    candidateSkills?.length
                      ? ["Candidate Skills", "Job Listing Skills"]
                      : ""
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <DeleteConfirmModal
          showModal={deleteModal?.show}
          handleOnClose={closeDeleteModal}
          onCancel={closeDeleteModal}
          onConfirm={closeJob}
          confirmText={`Are you sure you want to ${deleteModal?.action} this job?`}
          itemName={`"${jobToDelete?.title}"`}
          loading={closeRequesting || deleteRequesting}
          confirmLabel={deleteModal?.action === "delete" ? "Delete" : "Close"}
        />
        </>
      )}

    </>
  )
}
const mapStateToProps = state => ({
  skillsScore: state.jobDetails.skillsScore,
  userType: state.login.userType,
  requesting: state.recruiterJobs.requesting,
  closeRequesting: state.recruiterJobs.closeRequesting,
  deleteRequesting: state.recruiterJobs.deleteRequesting,
  jobDetails: state.jobDetails.jobInformation
})

const mapDispatchToProps = dispatch => ({
  getSkillsScore: data => dispatch(getSkillsScoreRequest(data)),
  closeJobDispatch: data => dispatch(closeJobRequest(data)),
  deleteJobDispatch: data => dispatch(deleteJobRequest(data)),
  getJobData: data => dispatch(getJobDataByIdRequest(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(JobDetails)
