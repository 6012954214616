import { all, call, put, takeLatest } from "redux-saga/effects"
// config
import { BASE_URL } from "../../../../config/app"
// utils
import XHR from "../../../../utils/XHR"
// types
import {
  GET_CANDIDATE_SAVED_JOBS_REQUEST,
  GET_CANDIDATE_APPLY_JOBS_REQUEST,
  POST_CANDIDATE_SAVED_JOB_REQUEST,
  POST_CANDIDATE_APPLY_JOB_REQUEST,
  GET_ALL_CANDIDATE_JOBS,
  GET_CANDIDATE_ARCHIVED_JOBS_REQUEST,
  GET_CANDIDATE_INTERVIEWING_JOBS_REQUEST,
  GET_CANDIDATE_OFFERED_JOBS_REQUEST,
  GET_CANDIDATE_HIRED_JOBS_REQUEST,
  GET_CANDIDATE_REJECTED_JOBS_REQUEST,
  GET_JOB_DATA_BY_ID_REQUEST,
  GENERATE_FINGERPRINT_REQUEST
} from "./type"
// actions
import {
  getSavedJobsSuccess,
  getSavedJobsFailure,
  getApplyJobsFailure,
  getApplyJobsSuccess,
  postApplyJobSuccess,
  postSavedJobSuccess,
  getAllCandidateJobsSuccess,
  getAllCandidateJobsFailure,
  postApplyJobFailure,
  postSavedJobFailure,
  getSavedJobsRequest,
  getApplyJobsRequest,
  getArchivedJobsSuccess,
  getArchivedJobsFailure,
  getInterviewingJobsFailure,
  getInterviewingJobsSuccess,
  getOfferedJobsSuccess,
  getOfferedJobsFailure,
  getHiredJobsSuccess,
  getHiredJobsFailure,
  getRejectedJobsSuccess,
  getRejectedJobsFailure,
  getJobDataByIdRequestSuccess,
  getJobDataByIdRequestFailure,
  displayJobsModal,
  generateFingerprintRequest
} from "./action"
import toast from "react-hot-toast"

// apply job get and post

async function getJobsByStatusAPI(data) {
  const searchParam = data?.searchParam
  const searchString = searchParam ? `&searchParams=${searchParam}` : ""
  const URL = `${BASE_URL}/api/v1/candidate/job-application/?candidate_application_status=${data?.status}${searchString}`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getApplyJobsData({ data }) {
  try {
    const response = yield call(getJobsByStatusAPI, {
      ...data,
      status: "applied"
    })
    yield put(getApplyJobsSuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(getApplyJobsFailure(response?.data))
  }
}

function* getArchivedJobsData({ data }) {
  try {
    const response = yield call(getJobsByStatusAPI, {
      ...data,
      status: "archived"
    })
    yield put(getArchivedJobsSuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(getArchivedJobsFailure(response?.data))
  }
}

function* getInterviewingJobsData({ data }) {
  try {
    const response = yield call(getJobsByStatusAPI, {
      ...data,
      status: "interviewing"
    })
    yield put(getInterviewingJobsSuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(getInterviewingJobsFailure(response?.data))
  }
}

function* getOfferedJobsData({ data }) {
  try {
    const response = yield call(getJobsByStatusAPI, {
      ...data,
      status: "offered"
    })
    yield put(getOfferedJobsSuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(getOfferedJobsFailure(response?.data))
  }
}

function* getHiredJobsData({ data }) {
  try {
    const response = yield call(getJobsByStatusAPI, { data, status: "hired" })
    yield put(getHiredJobsSuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(getHiredJobsFailure(response?.data))
  }
}

function* getRejectedJobsData({ data }) {
  try {
    const response = yield call(getJobsByStatusAPI, {
      ...data,
      status: "rejected"
    })
    yield put(getRejectedJobsSuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(getRejectedJobsFailure(response?.data))
  }
}

function* getSavedJobsData({ data }) {
  try {
    const response = yield call(getJobsByStatusAPI, {
      ...data,
      status: "saved"
    })
    yield put(getSavedJobsSuccess(response?.data))
  } catch (e) {
    const { response } = e

    yield put(getSavedJobsFailure(response?.data))
  }
}

async function postJobStatusAPI(data) {
  const putUrl = data?.exists ? `${data?.exists?.id}/` : ""
  const URL = `${BASE_URL}api/v1/candidate/job-application/${putUrl}`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: putUrl ? "PUT" : "POST",
    data: { job_id: data?.id, candidate_application_status: data?.status }
  }
  return XHR(URL, options)
}

function* postApplyJobRequest({ data, callback }) {
  try {
    const response = yield call(postJobStatusAPI, {
      ...data,
      status: "applied"
    })
    yield put(postApplyJobSuccess(response?.data))
    yield put(getApplyJobsRequest())
    yield put(getSavedJobsRequest())
    toast.success(`You have successfully applied to the job ${data?.title}`)
    callback(response)
  } catch (e) {
    const { response } = e
    yield put(postApplyJobFailure(response?.data))
    toast.error("Some error occurred while applying for the job")
    callback(response)
  }
}

function* postSavedJobRequest({ data }) {
  try {
    const response = yield call(postJobStatusAPI, { ...data, status: "saved" })
    yield put(postSavedJobSuccess(response))
    yield put(getSavedJobsRequest())
    yield put(getApplyJobsRequest())
    toast.success(`Successfully saved the job ${data?.title}`)
  } catch (e) {
    const { response } = e
    yield put(postSavedJobFailure(response?.data))
    toast.error("Some error occurred while saving the job")
  }
}

async function fetchAllCandidateJobs(
  data = {
    page: 1,
    limit: 10,
    sortBy: "job_created_date",
    sortDir: "desc",
    searchParam: ""
  }
) {
  const { page, limit, sortBy, sortDir, searchParam } = data
  const searchString = searchParam ? `&searchParams=${searchParam}` : ""
  const queryString = `?page=${page}&limit=${limit}&sortBy=${sortBy}&sortDir=${sortDir}${searchString}`
  const URL = `${BASE_URL}/api/v1/candidate/jobs/${queryString}`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getAllCandidateJobsData({ data }) {
  try {
    const response = yield call(fetchAllCandidateJobs, data)

    const jobIds = response?.data?.data?.map(job => job.id)
    yield put(generateFingerprintRequest({ job_ids: jobIds }))
    yield put(getAllCandidateJobsSuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(getAllCandidateJobsFailure(response?.data))
  }
}

async function fetchJobInfoById(data) {
  const jobId = data?.id ? `${data?.id}/` : ""
  const URL = `${BASE_URL}api/v1/recruiter/jobs/${jobId}`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET",
    data
  }
  return XHR(URL, options)
}

function* getJobByID({ data }) {
  try {
    const response = yield call(fetchJobInfoById, data)
    yield put(getJobDataByIdRequestSuccess(response?.data))
    yield put(displayJobsModal(true))
  } catch (e) {
    const { response } = e
    yield put(getJobDataByIdRequestFailure(response?.data))
    yield put(displayJobsModal(false))
  }
}

async function generateFingerprintAPI(data) {
  const URL = `${BASE_URL}api/v1/uiba-api/generatefngrprint/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* generateFingerprint({ data }) {
  try {
    yield call(generateFingerprintAPI, data)
  } catch (e) {
    const { response } = e
  }
}

export default all([
  takeLatest(GET_CANDIDATE_APPLY_JOBS_REQUEST, getApplyJobsData),
  takeLatest(POST_CANDIDATE_APPLY_JOB_REQUEST, postApplyJobRequest),
  takeLatest(POST_CANDIDATE_SAVED_JOB_REQUEST, postSavedJobRequest),
  takeLatest(GET_CANDIDATE_SAVED_JOBS_REQUEST, getSavedJobsData),
  takeLatest(GET_ALL_CANDIDATE_JOBS, getAllCandidateJobsData),
  takeLatest(GET_CANDIDATE_ARCHIVED_JOBS_REQUEST, getArchivedJobsData),
  takeLatest(GET_CANDIDATE_INTERVIEWING_JOBS_REQUEST, getInterviewingJobsData),
  takeLatest(GET_CANDIDATE_OFFERED_JOBS_REQUEST, getOfferedJobsData),
  takeLatest(GET_CANDIDATE_HIRED_JOBS_REQUEST, getHiredJobsData),
  takeLatest(GET_CANDIDATE_REJECTED_JOBS_REQUEST, getRejectedJobsData),
  takeLatest(GET_JOB_DATA_BY_ID_REQUEST, getJobByID),
  takeLatest(GENERATE_FINGERPRINT_REQUEST, generateFingerprint)
])
