import { all, call, put, takeLatest } from "redux-saga/effects"
// config
import { BASE_URL } from "../../../../config/app"
// utils
import XHR from "../../../../utils/XHR"
// types
import {
  GET_MY_JOBS_REQUEST,
  GET_ALL_JOBS_REQUEST,
  DELETE_JOB_REQUEST,
  CLOSE_JOB_REQUEST
} from "./type"
// actions
import {
  getMyJobsSuccess,
  getMyJobsFailure,
  getAllJobsSuccess,
  getAllJobsFailure,
  deleteJobSuccess,
  closeJobSuccess
} from "./action"
import toast from "react-hot-toast"
import { throttle } from 'lodash'

// TODO Memoize these calls to save trips to the server

const getJobsDataAPIThrottled = throttle(getJobsDataAPI, 1000, {trailing: false})

async function getJobsDataAPI(
  data = {
    page: 1,
    limit: 10,
    job: "alljob",
    sortBy: "",
    sortDir: "desc",
    searchParam: ""
  }
) {
  const { page, limit, job, section, sortBy, sortDir, searchParam } = data
  const endpoint = section && job !== "alljob" ? section : "list-jobs"
  const searchString = searchParam ? `&searchParams=${searchParam}` : ""
  const queryString = `?page=${page}&limit=${limit}&job=${job}&sortBy=${sortBy}&sortDir=${sortDir}${searchString}`
  const URL = `${BASE_URL}api/v1/recruiter/${endpoint}/${queryString}`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getMyJobsData({ data }) {
  try {
    const response = yield call(getJobsDataAPIThrottled, { ...data, job: "myjob" })
    yield put(getMyJobsSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getMyJobsFailure(response.data))
  }
}

function* getAllJobsData({ data }) {
  try {
    const response = yield call(getJobsDataAPIThrottled, { ...data, job: "alljob" })
    yield put(getAllJobsSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getAllJobsFailure(response.data))
  }
}

function deleteJobAPI(data) {
  const URL = `${BASE_URL}api/v1/recruiter/jobs/${data?.id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "DELETE"
  }
  return XHR(URL, options)
}

function* deleteJob({ data }) {
  try {
    const response = yield call(deleteJobAPI, data)
    yield put(deleteJobSuccess(response.data))
    toast.success(`Successfully deleted the job "${data?.title}".`)
  } catch (e) {
    const { response } = e
    yield put(deleteJobSuccess(response?.data))
    toast.error(
      `An error occurred while deleting the job "${data?.title}, please try again later".`
    )
  }
}

function closeJobAPI(data) {
  const URL = `${BASE_URL}api/v1/recruiter/jobs/${data?.id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "PUT",
    data: { status: "archive" }
  }
  return XHR(URL, options)
}

function* closeJob({ data }) {
  try {
    const response = yield call(closeJobAPI, data)
    yield put(closeJobSuccess(response.data))
    toast.success(`Successfully closed the job "${data?.title}".`)
  } catch (e) {
    const { response } = e
    yield put(closeJobSuccess(response?.data))
    toast.error(
      `An error occurred while closing the job "${data?.title}, please try again later".`
    )
  }
}

export default all([
  takeLatest(GET_MY_JOBS_REQUEST, getMyJobsData),
  takeLatest(GET_ALL_JOBS_REQUEST, getAllJobsData),
  takeLatest(DELETE_JOB_REQUEST, deleteJob),
  takeLatest(CLOSE_JOB_REQUEST, closeJob)
])
