import { useDropzone } from "react-dropzone"
import { useDispatch, useSelector } from "react-redux"
import React, { useMemo, useEffect, useState } from "react"
import ProgressBar from "react-bootstrap/ProgressBar"
import toast from "react-hot-toast"
import CircularProgress from "@mui/material/CircularProgress"

import { Images } from "../../theme/Images"
import Image from "../Image"
import {
  uploadResumeSuccess,
  uploadResumeRequest,
  uploadResumeFailure
} from "../../pages/CandidateScreens/Profile/redux/action"
import { formatBytes } from "../../utils/commonFunction"
import '../UploadFile/style.scss'

const focusedStyle = {
  borderColor: "#03522d"
}

const ResumeUpload = ({loadProfileData, open, closeModal, withParser, setUploadedResume}) => {
    const [error, setError] = useState("")
    const [dropzoneFiles, setDropZoneFiles] = useState({
        accepted: [],
        rejected: []
    })
    const dispatch = useDispatch()
    const uploadSuccess = useSelector(state => state.profile.uploadResumeSuccess)
    const uploadFailure = useSelector(state => state.profile.uploadResumeFailure)

    const maxFileSize = 1024 * 1024 * 3 //3 MB
    const [uploadStatus, setUploadStatus] = useState({
        uploading: false,
        percentCompleted: 0
    })
    const { uploading, percentCompleted } = uploadStatus


    useEffect(() => {
        setUploadStatus({
          uploading: false,
          percentCompleted: 0
        })
        setError("")
        setDropZoneFiles({ accepted: [], rejected: [] })
      }, [open])

      useEffect(() => {
        if (uploadFailure) {
          setUploadStatus({
            uploading: false,
            percentCompleted: 0
          })
          setError(
            `Something went wrong while uploading your resume.${
              uploadFailure?.error_message
                ? "\n\n" + uploadFailure?.error_message
                : ""
            }`
          )
          setUploadedResume()
          dispatch(uploadResumeFailure(false))
        }
      }, [dispatch, uploadFailure])

      useEffect(() => {
        if (uploadSuccess) {
          setUploadStatus({
            uploading: false,
            percentCompleted: 0
          })
          loadProfileData()
          closeModal()
          toast.success("Resume uploaded Successfully")
          setUploadedResume?.(dropzoneFiles?.accepted)
          dispatch(uploadResumeSuccess(false))
        }
      }, [dispatch, uploadSuccess, loadProfileData])

      const handleFileUpload = files => {
        if (!files.length) {
          return false
        }

        const formData = new FormData()
        formData.append("file", files[0])
        formData.append('with_parser', withParser)
        setError("")

        dispatch(uploadResumeRequest({ formData, setUploadStatus }))
      }

      const fileSizeValidation = file => {
        if (file?.size > maxFileSize) {
          return {
            code: "file-size-too-large",
            message: `Max file size allowed ${formatBytes(maxFileSize, 2)}.`
          }
        }

        return null
      }

      const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps,
        isFocused,
        isDragActive
      } = useDropzone({
        maxFiles: 1,
        accept: {
          "application/msword": [".doc"],
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            [".docx"],
          "application/pdf": [".pdf"]
        },
        onDrop: handleFileUpload,
        validator: fileSizeValidation
      })

      const style = useMemo(
        () => ({
          ...(isFocused ? focusedStyle : {})
        }),
        [isFocused]
      )

      useEffect(() => {
        setDropZoneFiles({
          accepted: acceptedFiles,
          rejected: fileRejections
        })
      }, [fileRejections, acceptedFiles])

      const files = dropzoneFiles?.accepted?.map(file => (
        <li key={file.path}>
          {file.path} - {formatBytes(file.size, 2)}
        </li>
      ))

      const fileRejectionItems = dropzoneFiles?.rejected?.map(
        ({ file, errors }) => (
          <li key={file.path}>
            {file.path} - {formatBytes(file.size, 2)} --{" "}
            {errors
              .map(e =>
                e.code === "file-invalid-type"
                  ? "Upload file type must be DOC, DOCX or PDF format."
                  : e.message
              )
              ?.join(" ")}
          </li>
        )
      )

    return (
        <>
            {uploading && (
            <ProgressBar now={percentCompleted} label={`${percentCompleted}%`} />
            )}
            <div
            {...getRootProps({
                style,
                className: "upload-resume-modal__dropbox"
            })}
            >
            {uploading ? (
                <>
                {percentCompleted >= 100
                    ? "Please wait while we are processing your resume."
                    : "Uploading..."}
                <CircularProgress color="inherit" />
                </>
            ) : (
                <>
                <input {...getInputProps()} />
                <div>
                    <Image
                    src={Images?.uploadIcon}
                    className="upload-resume-modal__dropbox__pdf-icon"
                    />
                </div>
                {isDragActive ? (
                    <div className="upload-resume-modal__dropbox__drop-text">
                    Drop the files here
                    </div>
                ) : (
                    <>
                    <div className="upload-resume-modal__dropbox__drop-text">
                        Upload new file
                    </div>
                    </>
                )}
                </>
            )}
            </div>
            <aside>
            {fileRejectionItems.length === 1 ? (
                <ul className="upload-resume-modal__file-reject">
                {fileRejectionItems}
                </ul>
            ) : (
                files.length === 1 && (
                <ul className="upload-resume-modal__file-accept">{files}</ul>
                )
            )}
            </aside>
            {error && <div className="upload-resume-modal__error">{error}</div>}
            <div className="upload-resume-modal__file-type-info">
                Upload file in DOC, DOCX or PDF format - the maximum file size is
                &nbsp;{formatBytes(maxFileSize, 2)}.
            </div>
        </>
    )
}

export default ResumeUpload
