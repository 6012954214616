import AddIcon from "@mui/icons-material/Add"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import dayjs from "dayjs"
import React from "react"

import { Images } from "../../../theme/Images"
import IconButton from "../../IconButton"
import Image from "../../Image"

import "../style.scss"

const EducationCard = ({
  isEdited,
  getEducationInfo,
  onClick,
  isCandidate
}) => {
  const handleOnClick = id => () => {
    onClick(id)
  }

  return (
    <>
      {isEdited ? (
        <div className="profile-details-card__body column-flex">
          {getEducationInfo?.map((item, index) => (
            <div className="profile-details-card__body__education" key={index}>
              <div className="profile-details-card__body__education__icon flex-justify-start">
                <Image src={Images.educationIcon} />
              </div>
              <div className="profile-details-card__body__education__content column-flex">
                <div className="profile-details-card__body__education__content__title flex-justify-between">
                  <div className="profile-details-card__body__education__content__title__school">
                    {item?.school}
                  </div>
                  {isCandidate && (
                    <div
                      className="profile-details-card__body__education__content__title__edit"
                      onClick={handleOnClick(item?.id)}
                    >
                      <EditOutlinedIcon />
                      &nbsp;&nbsp;Edit
                    </div>
                  )}
                </div>
                <div className="profile-details-card__body__education__content__degree">
                  {item.degree || "-"}
                </div>
                <div className="profile-details-card__body__education__content__date">
                  <span className="profile-details-card__body__education__content__date__start">
                    {item?.start_date &&
                      dayjs(item?.start_date).format("MMM YYYY ")}
                  </span>
                  <span className="profile-details-card__body__education__content__date__end">
                    {item?.still_studying
                      ? " - Present"
                      : item?.end_date &&
                        ` - ${dayjs(item?.end_date).format("MMM YYYY ")}`}
                  </span>
                </div>
                <div className="profile-details-card__body__education__content__subject">
                  <span className="profile-details-card__body__education__content__subject__title">
                    Major:
                  </span>
                  <span className="profile-details-card__body__education__content__subject__name">
                    {item.major}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="profile-details-card__info-text">
          Please include your education history with the dates of attendance and
          degrees earned. Minimum of 1 requested.
        </div>
      )}
      {isCandidate && (
        <div className="profile-details-card__action">
          <IconButton
            icon={<AddIcon />}
            label="Add Education"
            onClick={handleOnClick()}
          />
        </div>
      )}
    </>
  )
}

export default EducationCard
