import { connect } from "react-redux"
import React, { useState, useEffect } from "react"
import TablePagination from "@mui/material/TablePagination"
import dayjs from "dayjs"
import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"

import {
  getJobDataByIdRequest,
  displayJobsModal
} from "../../Home/redux/action"
import USearch from "../../../../components/USearch"
import JobsModal from "../../../../components/AuthModals/JobsModal"

import "./style.scss"
import TablePaginationActions from "../../../../components/DataTable/components/TablePaginationActions"
import { getFormattedLocation } from "../../../../utils/helper"
import { useHistory } from "react-router-dom"
import { userTypeCandidate } from "../../../../utils/constants"
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

const SavedJobs = props => {
  const {
    dispatchJobInfo,
    jobInformation,
    showJobsModal,
    sectionData,
    closeJobsModal,
    candidateSkills,
    requesting
  } = props
  const history = useHistory()
  const { getMethod } = sectionData
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [searchParam, setSearchParm] = useState("")

  useEffect(() => {
    getMethod({ searchParam })
  }, [getMethod, searchParam])

  useEffect(() => {
    if (showJobsModal && jobInformation?.length) {
      history.push(`/${userTypeCandidate}/job-details/${jobInformation[0]?.id}`, {
        jobData: jobInformation[0],
        candidateSkills
      })
    }

    return () => closeJobsModal()
  }, [showJobsModal, jobInformation, history, closeJobsModal, candidateSkills])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const getUpdatedAtTime = item => {
    let days
    if (item?.updated_at == null) {
      days = dayjs(item?.created_at).fromNow()
    } else {
      days = dayjs(item?.updated_at).fromNow()
    }
    return `${sectionData?.status} ${days}`
  }

  const handleJobCLick = item => () => {
    let data = {
      id: item?.job_id
    }
    dispatchJobInfo(data)
  }

  const handleSearch = searchTerm => {
    setPage(0)
    setSearchParm(searchTerm)
  }

  return (
    <section className="jobs-main-section">
      <Backdrop
        sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
        open={sectionData?.loading || requesting}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="header">
        <USearch
          placeholder="Search job title, keywords, or company name..."
          onClick={handleSearch}
          title="Find Jobs"
        />
        <div className="heading-div">
          <h3>{sectionData.header}</h3>
        </div>
      </div>
      <div className="saved-job-section">
        <div className="saved-job-sub-section">
          {!sectionData?.loading &&
            (sectionData?.data.length > 0 ? (
              <div className="saved-job-row">
                {sectionData?.data
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(item => {
                    return (
                      <div
                        className="saved-job"
                        onClick={handleJobCLick(item)}
                        key={`${sectionData.header}-${item?.job_id}`}
                      >
                        <div className="job-info">
                          <h3 className="job-title">{item?.job}</h3>
                          <p className="job-company">{item?.job_company}</p>
                          <p className="job-location">
                            {getFormattedLocation(item?.job_location)}
                          </p>
                        </div>
                        <div className="job-updated-time">
                          {getUpdatedAtTime(item)}
                        </div>
                      </div>
                    )
                  })}
              </div>
            ) : (
              <div className="no-jobs-message">No jobs found</div>
            ))}
        </div>
      </div>
      <div className="pagination">
        {sectionData?.data.length > 0 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={sectionData?.data?.length || 1}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
            labelDisplayedRows={({ page }) => {
              return `Page ${page + 1} of ${Math.ceil(
                (sectionData?.data?.length || 1) / rowsPerPage
              )}`
            }}
          />
        )}
      </div>
      {/*showJobsModal && (
        <JobsModal showJobsModal={showJobsModal} jobsData={jobInformation} />
      )*/}
    </section>
  )
}

const mapStateToProps = state => ({
  jobInformation: state.candidateJobs.jobInformation,
  requesting: state.candidateJobs.requesting,
  showJobsModal: state.candidateJobs.showModal,
  candidateSkills: state.profile.skills
})

const mapDispatchToProps = dispatch => ({
  dispatchJobInfo: data => dispatch(getJobDataByIdRequest(data)),
  closeJobsModal: data => dispatch(displayJobsModal(false))
})

export default connect(mapStateToProps, mapDispatchToProps)(SavedJobs)
