export const languageProficiencyLevels = [
  { key: "Elementary", label: "Elementary", value: 0, code: 1 },
  { key: "Limited", label: "Limited", value: 33, code: 2 },
  { key: "Proficient", label: "Proficient", value: 66, code: 3 },
  { key: "Fluent", label: "Fluent", value: 100, code: 4 }
]

export const skillProficiency = [
  { key: "Learning", label: "Learning", value: 0, code: 1 },
  { key: "Intermediate", label: "Intermediate", value: 33, code: 2 },
  { key: "competent", label: "Competent", value: 66, code: 3 },
  { key: "Expert", label: "Expert", value: 100, code: 4 }
]

export const workTimezones = [
  { key: "US", label: "US" },
  { key: "Europe", label: "Europe" }
]

export const OnboardingTypes = [
  { key: "B2B", label: "B2B" },
  { key: "Freelance", label: "Freelance" },
  { key: "Payroll", label: "Payroll" }
]

export const jobStatus = [
  { key: "applied", label: "Applied" },
  { key: "recommended", label: "Recommended" },
  { key: "selected", label: "Shortlisted" },
  { key: "submitted", label: "Submitted" },
  { key: "rejected", label: "Rejected" },
  { key: "interviewing", label: "Interviewing" },
  //{ key: "confirmed", label: "Confirmed" },
  { key: "hired", label: "Offered" },
  { key: "placed", label: "Placed" }
]
export const mile = [{ label: "25" }, { label: "40" }, { label: "60" }]

export const userTypeAdmin = "admin"
export const userTypeRecruiter = "recruiter"
export const userTypeCandidate = "candidate"

export const chartColors = {
  blue: "#1D8EE6",
  orange: "#FFAD6B",
  yellow: "#FFE500",
  red: "#FF2A2A",
  green: "#21B731"
}
export const colorOrder = ["red", "orange", "yellow", "blue", "green"]

export const locationCohortConf = [
  {
    label: "Others",
    color: "blue",
    key: "Others"
  },
  {
    label: "Spain",
    color: "orange",
    key: "Spain"
  },
  {
    label: "Canada",
    color: "yellow",
    key: "Canada"
  },
  {
    label: "USA",
    color: "red",
    key: "US"
  },
  {
    label: "UK",
    color: "green",
    key: "UK"
  }
]

export const currencyCohortConf = [
  { label: "Others", color: "blue", key: "Others" },
  { label: "EUR", color: "orange", key: "EUR" },
  { label: "CAD", color: "yellow", key: "CAD" },
  { label: "USD", color: "red", key: "USD" },
  { label: "GBP", color: "green", key: "GBP" }
]

export const onboardingCohortConf = [
  { label: "B2B", color: "blue", key: "B2B" },
  { label: "Freelance", color: "orange", key: "Freelance" },
  { label: "Payroll", color: "yellow", key: "Payroll" },
  { label: "Not Updated", color: "red", key: "Not Updated" }
]

export const experienceCohortConf = [
  { label: "5 years", color: "blue", key: "5 Years" },
  { label: "3 years", color: "orange", key: "3 Years" },
  { label: "2 years", color: "yellow", key: "2 Years" },
  { label: "1 year", color: "green", key: "1 Years" }
]

export const certificateCohortConf = [
  { label: "1-3", color: "blue", key: "1-3" },
  { label: "3-5", color: "orange", key: "3-5" },
  { label: "5-15", color: "yellow", key: "5-15" }
]

export const educationCohortConf = [
  { label: "Bachelors", color: "blue", key: "Bachelors" },
  { label: "Masters", color: "orange", key: "Masters" },
  { label: "PhD", color: "yellow", key: "PHDs" },
  { label: "others", color: "red", key: "Others" }
]

export const dateFilters = [
  {
    key: "last-week",
    label: "Last week",
    unit: "weeks",
    value: "1"
  },
  {
    key: "last-month",
    label: "Last month",
    unit: "months",
    value: "1"
  },
  {
    key: "last-3-months",
    label: "Last 3 months",
    unit: "months",
    value: "3"
  },
  {
    key: "last-6-months",
    label: "Last 6 months",
    unit: "months",
    value: "6"
  },
  {
    key: "1-year",
    label: "Last year",
    unit: "years",
    value: "1"
  },
  {
    key: "custom-date",
    label: "Custom date range",
    unit: "",
    value: ""
  }
]

export const languageList = [
  {
    key: "af",
    label: "Afrikaans"
  },
  {
    key: "sq",
    label: "Albanian"
  },
  {
    key: "am",
    label: "Amharic"
  },
  {
    key: "ar",
    label: "Arabic"
  },
  {
    key: "hy",
    label: "Armenian"
  },
  {
    key: "as",
    label: "Assamese"
  },
  {
    key: "ay",
    label: "Aymara"
  },
  {
    key: "az",
    label: "Azerbaijani"
  },
  {
    key: "bm",
    label: "Bambara"
  },
  {
    key: "eu",
    label: "Basque"
  },
  {
    key: "be",
    label: "Belarusian"
  },
  {
    key: "bn",
    label: "Bengali"
  },
  {
    key: "bho",
    label: "Bhojpuri"
  },
  {
    key: "bs",
    label: "Bosnian"
  },
  {
    key: "bg",
    label: "Bulgarian"
  },
  {
    key: "ca",
    label: "Catalan"
  },
  {
    key: "ceb",
    label: "Cebuano"
  },
  {
    key: "ny",
    label: "Chichewa"
  },
  {
    key: "zh-CN",
    label: "Chinese (Simplified)"
  },
  {
    key: "zh-TW",
    label: "Chinese (Traditional)"
  },
  {
    key: "co",
    label: "Corsican"
  },
  {
    key: "hr",
    label: "Croatian"
  },
  {
    key: "cs",
    label: "Czech"
  },
  {
    key: "da",
    label: "Danish"
  },
  {
    key: "dv",
    label: "Dhivehi"
  },
  {
    key: "doi",
    label: "Dogri"
  },
  {
    key: "nl",
    label: "Dutch"
  },
  {
    key: "en",
    label: "English"
  },
  {
    key: "eo",
    label: "Esperanto"
  },
  {
    key: "et",
    label: "Estonian"
  },
  {
    key: "ee",
    label: "Ewe"
  },
  {
    key: "tl",
    label: "Filipino"
  },
  {
    key: "fi",
    label: "Finnish"
  },
  {
    key: "fr",
    label: "French"
  },
  {
    key: "fy",
    label: "Frisian"
  },
  {
    key: "gl",
    label: "Galician"
  },
  {
    key: "ka",
    label: "Georgian"
  },
  {
    key: "de",
    label: "German"
  },
  {
    key: "el",
    label: "Greek"
  },
  {
    key: "gn",
    label: "Guarani"
  },
  {
    key: "gu",
    label: "Gujarati"
  },
  {
    key: "ht",
    label: "Haitian Creole"
  },
  {
    key: "ha",
    label: "Hausa"
  },
  {
    key: "haw",
    label: "Hawaiian"
  },
  {
    key: "iw",
    label: "Hebrew"
  },
  {
    key: "hi",
    label: "Hindi"
  },
  {
    key: "hmn",
    label: "Hmong"
  },
  {
    key: "hu",
    label: "Hungarian"
  },
  {
    key: "is",
    label: "Icelandic"
  },
  {
    key: "ig",
    label: "Igbo"
  },
  {
    key: "ilo",
    label: "Ilocano"
  },
  {
    key: "id",
    label: "Indonesian"
  },
  {
    key: "ga",
    label: "Irish"
  },
  {
    key: "it",
    label: "Italian"
  },
  {
    key: "ja",
    label: "Japanese"
  },
  {
    key: "jw",
    label: "Javanese"
  },
  {
    key: "kn",
    label: "Kannada"
  },
  {
    key: "kk",
    label: "Kazakh"
  },
  {
    key: "km",
    label: "Khmer"
  },
  {
    key: "rw",
    label: "Kinyarwanda"
  },
  {
    key: "gom",
    label: "Konkani"
  },
  {
    key: "ko",
    label: "Korean"
  },
  {
    key: "kri",
    label: "Krio"
  },
  {
    key: "ku",
    label: "Kurdish (Kurmanji)"
  },
  {
    key: "ckb",
    label: "Kurdish (Sorani)"
  },
  {
    key: "ky",
    label: "Kyrgyz"
  },
  {
    key: "lo",
    label: "Lao"
  },
  {
    key: "la",
    label: "Latin"
  },
  {
    key: "lv",
    label: "Latvian"
  },
  {
    key: "ln",
    label: "Lingala"
  },
  {
    key: "lt",
    label: "Lithuanian"
  },
  {
    key: "lg",
    label: "Luganda"
  },
  {
    key: "lb",
    label: "Luxembourgish"
  },
  {
    key: "mk",
    label: "Macedonian"
  },
  {
    key: "mai",
    label: "Maithili"
  },
  {
    key: "mg",
    label: "Malagasy"
  },
  {
    key: "ms",
    label: "Malay"
  },
  {
    key: "ml",
    label: "Malayalam"
  },
  {
    key: "mt",
    label: "Maltese"
  },
  {
    key: "mi",
    label: "Maori"
  },
  {
    key: "mr",
    label: "Marathi"
  },
  {
    key: "mni-Mtei",
    label: "Meiteilon (Manipuri)"
  },
  {
    key: "lus",
    label: "Mizo"
  },
  {
    key: "mn",
    label: "Mongolian"
  },
  {
    key: "my",
    label: "Myanmar (Burmese)"
  },
  {
    key: "ne",
    label: "Nepali"
  },
  {
    key: "no",
    label: "Norwegian"
  },
  {
    key: "or",
    label: "Odia (Oriya)"
  },
  {
    key: "om",
    label: "Oromo"
  },
  {
    key: "ps",
    label: "Pashto"
  },
  {
    key: "fa",
    label: "Persian"
  },
  {
    key: "pl",
    label: "Polish"
  },
  {
    key: "pt",
    label: "Portuguese"
  },
  {
    key: "pa",
    label: "Punjabi"
  },
  {
    key: "qu",
    label: "Quechua"
  },
  {
    key: "ro",
    label: "Romanian"
  },
  {
    key: "ru",
    label: "Russian"
  },
  {
    key: "sm",
    label: "Samoan"
  },
  {
    key: "sa",
    label: "Sanskrit"
  },
  {
    key: "gd",
    label: "Scots Gaelic"
  },
  {
    key: "nso",
    label: "Sepedi"
  },
  {
    key: "sr",
    label: "Serbian"
  },
  {
    key: "st",
    label: "Sesotho"
  },
  {
    key: "sn",
    label: "Shona"
  },
  {
    key: "sd",
    label: "Sindhi"
  },
  {
    key: "si",
    label: "Sinhala"
  },
  {
    key: "sk",
    label: "Slovak"
  },
  {
    key: "sl",
    label: "Slovenian"
  },
  {
    key: "so",
    label: "Somali"
  },
  {
    key: "es",
    label: "Spanish"
  },
  {
    key: "su",
    label: "Sundanese"
  },
  {
    key: "sw",
    label: "Swahili"
  },
  {
    key: "sv",
    label: "Swedish"
  },
  {
    key: "tg",
    label: "Tajik"
  },
  {
    key: "ta",
    label: "Tamil"
  },
  {
    key: "tt",
    label: "Tatar"
  },
  {
    key: "te",
    label: "Telugu"
  },
  {
    key: "th",
    label: "Thai"
  },
  {
    key: "ti",
    label: "Tigrinya"
  },
  {
    key: "ts",
    label: "Tsonga"
  },
  {
    key: "tr",
    label: "Turkish"
  },
  {
    key: "tk",
    label: "Turkmen"
  },
  {
    key: "ak",
    label: "Twi"
  },
  {
    key: "uk",
    label: "Ukrainian"
  },
  {
    key: "ur",
    label: "Urdu"
  },
  {
    key: "ug",
    label: "Uyghur"
  },
  {
    key: "uz",
    label: "Uzbek"
  },
  {
    key: "vi",
    label: "Vietnamese"
  },
  {
    key: "cy",
    label: "Welsh"
  },
  {
    key: "xh",
    label: "Xhosa"
  },
  {
    key: "yi",
    label: "Yiddish"
  },
  {
    key: "yo",
    label: "Yoruba"
  },
  {
    key: "zu",
    label: "Zulu"
  }
]

export const DEFAULT_CURRENCY_CODE = "GBP"
export const ENTITY_URI_PREFIX = "http://www.wikidata.org/wiki/Special:EntityData/"
export const WIKIDATA_CONCEPT_URI_PREFIX = "http://www.wikidata.org/entity/"
export const MAX_SKILL_LABEL_LENGTH = 32
export const MAX_RADAR_CHART_LABEL_LENGTH = 24
export const IDLE_TIME_BEFORE_SESSION_EXPIRES_MINS = 120
export const DEFAULT_DATE_FORMAT = "DD/MM/YYYY" // UK date format
export const NOT_AVAILABLE = 'Not Available'
