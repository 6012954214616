import { all, call, delay, put, takeLatest } from "redux-saga/effects"

// config
import { BASE_URL } from "../../../../config/app"
// utils
import XHR from "../../../../utils/XHR"
// types
import {
  POST_PROFILE_CERTIFICATION_REQUEST,
  POST_PROFILE_EXPERIENCE_REQUEST,
  DELETE_PROFILE_EXPERIENCE_REQUEST,
  POST_PROFILE_HOBBIES_REQUEST,
  POST_PROFILE_INFO_REQUEST,
  POST_PROFILE_LANGUAGE_REQUEST,
  GET_PROFILE_LANGUAGE_REQUEST,
  DELETE_PROFILE_LANGUAGE_REQUEST,
  GET_PROFILE_PROJECT_REQUEST,
  POST_PROFILE_PROJECT_REQUEST,
  DELETE_PROFILE_PROJECT_REQUEST,
  POST_PROFILE_SALARY_REQUEST,
  POST_PROFILE_SKILL_REQUEST,
  GET_CANDIDATE_PROFILE_REQUEST,
  GET_PROFILE_SALARY_REQUEST,
  GET_PROFILE_EXPERIENCE_REQUEST,
  GET_PROFILE_CERTIFICATION_REQUEST,
  GET_PROFILE_HOBBIES_REQUEST,
  GET_PROFILE_EDUCATION_REQUEST,
  POST_PROFILE_EDUCATION_REQUEST,
  DELETE_PROFILE_EDUCATION_REQUEST,
  POST_UPLOAD_RESUME_REQUEST,
  GET_PROFILE_SKILL_REQUEST,
  GET_PROFILE_SOCIAL_MEDIA_REQUEST,
  POST_PROFILE_SOCIAL_MEDIA_REQUEST,
  DELETE_PROFILE_SKILL_REQUEST,
  POST_JOB_CONSENT_REQUEST,
  GET_PROFILE_REQUIRED_INFO_REQUEST
} from "./type"
// actions
import {
  profileEducationSuccess,
  profileEducationFailure,
  profileCertificationSuccess,
  profileCertificationFailure,
  profileExperienceSuccess,
  profileExperienceFailure,
  profileHobbiesSuccess,
  profileHobbiesFailure,
  profileInfoSuccess,
  profileInfoFailure,
  profileLanguageSuccess,
  profileLanguageFailure,
  profileProjectSuccess,
  profileProjectFailure,
  profileSalarySuccess,
  profileSalaryFailure,
  profileSkillSuccess,
  profileSkillFailure,
  getCandidateProfileRequestSuccess,
  getCandidateProfileRequestFailure,
  getCandidateProfileRequest,
  getProfileSkillRequest,
  getProfileExperienceRequest,
  getProfileCertificationRequest,
  getProfileProjectRequest,
  getProfileLanguageRequest,
  getProfileHobbiesRequest,
  getProfileEducationRequest,
  getProfileSalaryRequest,
  profileSocialMediaFailure,
  profileSocialMediaSuccess,
  uploadResumeFailure,
  uploadResumeSuccess,
  getProfileSocialMediaRequest,
  postJobConsentSuccess,
  postJobConsentFailure,
  profileRequiredInfoSuccess,
  profileRequiredInfoFailure
} from "./action"
import { getSelectedProfileRequest } from "../../../CommonScreens/SelectedProfile/redux/action"
import { toast } from "react-hot-toast"

// GET PROFILE CANDIDATe

const getCandidateProfileAPI = async () => {
  const URL = `${BASE_URL}api/v1/candidate/profile/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getCandidateProfile() {
  try {
    const response = yield call(getCandidateProfileAPI)
    yield put(
      getCandidateProfileRequestSuccess(
        Array.isArray(response?.data) ? response?.data[0] : response?.data
      )
    )
    getCandidateProfileRequestFailure(false)
  } catch (e) {
    const { response } = e
    yield put(getCandidateProfileRequestFailure(response?.data))
  }
}

async function profileInfoAPI(data) {
  const URL = `${BASE_URL}api/v1/candidate/profile/${
    data?.id ? data?.id + "/" : ""
  }`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: data?.id ? "PUT" : "POST",
    data
  }
  return XHR(URL, options)
}

function* profileInfoData({ data }) {
  try {
    const response = yield call(profileInfoAPI, data)
    yield put(
      profileInfoSuccess(
        Array.isArray(response?.data) ? response?.data[0] : response?.data
      )
    )
    if (data?.from === "recruiter") {
      yield put(getSelectedProfileRequest({ candidate_id: data?.candidateId }))
    } else {
      yield put(getCandidateProfileRequest())
    }
  } catch (e) {
    const { response } = e

    yield put(profileInfoFailure(response?.data))

    toast.error(
      "An error occurred while updating the profile info, please try again later."
    )
  }
}

async function getProfileEducationAPI(data) {
  const URL = `${BASE_URL}api/v1/candidate/education/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET",
    data
  }
  return XHR(URL, options)
}

function* getProfileEducationData({ data }) {
  try {
    const response = yield call(getProfileEducationAPI, data)
    yield put(profileEducationSuccess(response?.data))
  } catch (e) {
    const { response } = e

    yield put(profileEducationFailure(response?.data))
  }
}

async function profileEducationAPI(data) {
  const URL = `${BASE_URL}api/v1/candidate/education/${
    data?.id ? data?.id + "/" : ""
  }`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: data?.id ? "PUT" : "POST",
    data
  }
  return XHR(URL, options)
}

function* profileEducationData({ data }) {
  try {
    yield call(profileEducationAPI, data)
    yield put(getProfileEducationRequest())
  } catch (e) {
    const { response } = e

    yield put(profileEducationFailure(response?.data))
  }
}

async function deleteProfileEducationAPI(data) {
  const URL = `${BASE_URL}api/v1/candidate/education/${data?.id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "DELETE",
    data
  }
  return XHR(URL, options)
}

function* deleteProfileEducationData({ data }) {
  try {
    yield call(deleteProfileEducationAPI, data)
    yield put(getProfileEducationRequest())
  } catch (e) {
    const { response } = e

    yield put(profileEducationFailure(response?.data))
  }
}

async function getProfileCertificationAPI(data) {
  const URL = `${BASE_URL}api/v1/candidate/certification/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET",
    data
  }
  return XHR(URL, options)
}

function* getProfileCertificationData({ data }) {
  try {
    const response = yield call(getProfileCertificationAPI, data)
    yield put(profileCertificationSuccess(response?.data))
  } catch (e) {
    const { response } = e

    yield put(profileCertificationFailure(response?.data))
  }
}

async function profileCertificationAPI(data) {
  const URL = `${BASE_URL}api/v1/candidate/certification/${
    data?.id ? data?.id + "/" : ""
  }`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: data?.id ? "PUT" : "POST",
    data
  }
  return XHR(URL, options)
}

function* profileCertificationData({ data }) {
  try {
    const response = yield call(profileCertificationAPI, data)
    yield put(getProfileCertificationRequest(response?.data))
  } catch (e) {
    const { response } = e

    yield put(profileCertificationFailure(response?.data))
  }
}

async function getProfileExperienceAPI() {
  const URL = `${BASE_URL}api/v1/candidate/experience/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getProfileExperienceData({ data }) {
  try {
    const response = yield call(getProfileExperienceAPI)
    yield put(profileExperienceSuccess(response?.data))
  } catch (e) {
    const { response } = e

    yield put(profileExperienceFailure(response?.data))
  }
}

async function profileExperienceAPI(data) {
  const URL = `${BASE_URL}api/v1/candidate/experience/${
    data.id ? data.id + "/" : ""
  }`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: data.id ? "PUT" : "POST",
    data
  }
  return XHR(URL, options)
}

function* profileExperienceData({ data }) {
  try {
    yield call(profileExperienceAPI, data)
    yield put(getProfileExperienceRequest())
  } catch (e) {
    const { response } = e

    yield put(profileExperienceFailure(response?.data))
  }
}

async function deleteProfileExperienceAPI(data) {
  const URL = `${BASE_URL}api/v1/candidate/experience/${
    data.id ? data.id + "/" : ""
  }`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "Delete",
    data
  }
  return XHR(URL, options)
}

function* deleteProfileExperienceData({ data }) {
  try {
    yield call(deleteProfileExperienceAPI, data)
    yield put(getProfileExperienceRequest())
  } catch (e) {
    const { response } = e

    yield put(profileExperienceFailure(response?.data))
  }
}

async function getProfileHobbiesAPI(data) {
  const URL = `${BASE_URL}api/v1/candidate/hobbies/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET",
    data
  }
  return XHR(URL, options)
}

function* getProfileHobbiesData({ data }) {
  try {
    const response = yield call(getProfileHobbiesAPI, data)
    yield put(profileHobbiesSuccess(response?.data))
  } catch (e) {
    const { response } = e

    yield put(profileHobbiesFailure(response?.data))
  }
}

async function profileHobbiesAPI(data) {
  const URL = `${BASE_URL}api/v1/candidate/hobbies/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* profileHobbiesData({ data }) {
  try {
    const response = yield call(profileHobbiesAPI, data)
    yield put(getProfileHobbiesRequest(response?.data))
  } catch (e) {
    const { response } = e

    yield put(profileHobbiesFailure(response?.data))
  }
}

async function profileLanguageAPI(data) {
  const URL = `${BASE_URL}api/v1/candidate/language/${
    data?.id ? data?.id + "/" : ""
  }`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: data?.id ? "PUT" : "POST",
    data
  }
  return XHR(URL, options)
}

function* profileLanguageData({ data }) {
  try {
    const response = yield call(profileLanguageAPI, data)
    yield put(getProfileLanguageRequest(response?.data))
  } catch (e) {
    const { response } = e

    yield put(profileLanguageFailure(response?.data))
  }
}

async function deleteProfileLanguageAPI(data) {
  const URL = `${BASE_URL}api/v1/candidate/language/${data?.id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "DELETE",
    data
  }
  return XHR(URL, options)
}

function* deleteProfileLanguageData({ data }) {
  try {
    const response = yield call(deleteProfileLanguageAPI, data)
    yield put(getProfileLanguageRequest(response?.data))
  } catch (e) {
    const { response } = e

    yield put(profileLanguageFailure(response?.data))
  }
}

async function getProfileLanguageAPI() {
  const URL = `${BASE_URL}api/v1/candidate/language/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getProfileLanguageData() {
  try {
    const response = yield call(getProfileLanguageAPI)
    yield put(profileLanguageSuccess(response?.data))
  } catch (e) {
    const { response } = e

    yield put(profileLanguageFailure(response?.data))
  }
}

async function getProfileProjectAPI() {
  const URL = `${BASE_URL}api/v1/candidate/project/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getProfileProjectData({ data }) {
  try {
    const response = yield call(getProfileProjectAPI, data)
    yield put(profileProjectSuccess(response?.data))
  } catch (e) {
    const { response } = e

    yield put(profileProjectFailure(response?.data))
  }
}

async function profileProjectAPI(data) {
  const URL = `${BASE_URL}api/v1/candidate/project/${
    data?.id ? data?.id + "/" : ""
  }`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: data?.id ? "PUT" : "POST",
    data
  }
  return XHR(URL, options)
}

function* profileProjectData({ data }) {
  try {
    const response = yield call(profileProjectAPI, data)
    yield put(getProfileProjectRequest(response?.data))
  } catch (e) {
    const { response } = e

    yield put(profileProjectFailure(response?.data))
  }
}

async function deleteProfileProjectAPI(data) {
  const URL = `${BASE_URL}api/v1/candidate/project/${data?.id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "DELETE",
    data
  }
  return XHR(URL, options)
}

function* deleteProfileProjectData({ data }) {
  try {
    const response = yield call(deleteProfileProjectAPI, data)
    yield put(getProfileProjectRequest(response?.data))
  } catch (e) {
    const { response } = e

    yield put(profileProjectFailure(response?.data))
  }
}

async function getProfileSalaryAPI() {
  const URL = `${BASE_URL}api/v1/candidate/payment/`
  const token = await localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getProfileSalaryData({ data }) {
  try {
    const response = yield call(getProfileSalaryAPI, data)
    yield put(profileSalarySuccess(response?.data[0]))
  } catch (e) {
    const { response } = e
    yield put(profileSalaryFailure(response?.data))
  }
}

async function profileSalaryAPI(data) {
  const URL = `${BASE_URL}api/v1/candidate/payment/${
    data?.id ? data?.id + "/" : ""
  }`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: data?.id ? "PUT" : "POST",
    data
  }
  return XHR(URL, options)
}

function* profileSalaryData({ data }) {
  try {
    const response = yield call(profileSalaryAPI, data)
    yield put(getProfileSalaryRequest(response?.data))
  } catch (e) {
    const { response } = e

    yield put(profileSalaryFailure(response?.data))
  }
}

async function getProfileSkillAPI() {
  const URL = `${BASE_URL}api/v1/candidate/skill/`
  const token = await localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getProfileSkillData({ data }) {
  try {
    const response = yield call(getProfileSkillAPI, data)
    yield put(profileSkillSuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(profileSkillFailure(response?.data))
  }
}

async function profileSkillAPI(data) {
  const URL = `${BASE_URL}api/v1/candidate/skill/${
    data?.id ? data?.id + "/" : ""
  }`
  const token = await localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: data?.id ? "PUT" : "POST",
    data
  }
  return XHR(URL, options)
}

function* profileSkillData({ data, cb }) {
  try {
    yield call(profileSkillAPI, data)
    yield put(getProfileSkillRequest())
    cb?.()
  } catch (e) {
    const { response } = e
    yield put(profileSkillFailure(response?.data))
  }
}

async function deleteProfileSkillAPI(data) {
  const URL = `${BASE_URL}api/v1/candidate/skill/${data?.id}/`
  const token = await localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "DELETE",
    data
  }
  return XHR(URL, options)
}

function* deleteProfileSkillData({ data, cb }) {
  try {
    yield call(deleteProfileSkillAPI, data)
    yield put(getProfileSkillRequest())
    cb?.()
  } catch (e) {
    const { response } = e
    yield put(profileSkillFailure(response?.data))
  }
}

async function getProfileSocialMediaAPI() {
  const URL = `${BASE_URL}api/v1/candidate/social_media/`
  const token = await localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getProfileSocialMediaData({ data }) {
  try {
    const response = yield call(getProfileSocialMediaAPI, data)
    yield put(profileSocialMediaSuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(profileSocialMediaFailure(response?.data))
  }
}

async function profileSocialMediaAPI(data) {
  const URL = `${BASE_URL}api/v1/candidate/social_media/${
    data?.id ? data?.id + "/" : ""
  }`
  const token = await localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: data?.id ? "PUT" : "POST",
    data
  }
  return XHR(URL, options)
}

function* profileSocialMediaData({ data }) {
  try {
    yield call(profileSocialMediaAPI, data)
    yield put(getProfileSocialMediaRequest())
    const action = data?.id ? "updated" : "added"
    toast.success(`Successfully ${action} social media links`)
  } catch (e) {
    const { response } = e
    console.log(e)
    yield put(profileSocialMediaFailure(response?.data))
    const action = data?.id ? "updating" : "adding"
    toast.error(
      `An error occurred while ${action} social media links, please try again later.`
    )
  }
}

const postUploadResumeAPI = async (formData, setUploadStatus) => {
  const URL = `${BASE_URL}api/v1/candidate/upload-resume/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data: formData,
    onUploadProgress: progressEvent => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      )
      setUploadStatus({
        uploading: true,
        percentCompleted
      })
    }
  }

  setUploadStatus({
    uploading: true,
    percentCompleted: 0
  })

  return XHR(URL, options)
}

function* postUploadResume({ data }) {
  const { formData, setUploadStatus } = data

  try {
    yield call(postUploadResumeAPI, formData, setUploadStatus)
    yield put(uploadResumeSuccess(true))
  } catch (e) {
    const { response } = e
    yield put(uploadResumeFailure(response?.data))
  }
}

async function JobRecommendationConsentAPI(data) {
  const URL = `${BASE_URL}api/v1/candidate/job-recommendation-consent/`
  const token = await localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "PATCH",
    data
  }
  return XHR(URL, options)
}

function* JobRecommendationConsent({ data }) {
  try {
    yield call(JobRecommendationConsentAPI, data)
    yield put(getCandidateProfileRequest())
    yield delay(500)
    yield put(postJobConsentSuccess(data))
    toast.success(`Successfully updated the job recommendation consent`)
  } catch (e) {
    const { response } = e
    console.log(e)
    yield put(postJobConsentFailure(response?.data))
    toast.error(`An error occurred while updating job recommendation consent.`)
  }
}

async function getProfileRequiredInfoAPI() {
  const URL = `${BASE_URL}api/v1/candidate/candidate-required-info/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* profileRequiredInfoData({ data }) {
  try {
    const response = yield call(getProfileRequiredInfoAPI, data)
    console.log('~ profileRequiredInfo saga response', response)
    yield put(profileRequiredInfoSuccess(response?.data))
  } catch (e) {
    const { response } = e
    yield put(profileRequiredInfoFailure(response?.data))
  }
}


export default all([
  takeLatest(GET_PROFILE_EDUCATION_REQUEST, getProfileEducationData),
  takeLatest(POST_PROFILE_EDUCATION_REQUEST, profileEducationData),
  takeLatest(DELETE_PROFILE_EDUCATION_REQUEST, deleteProfileEducationData),
  takeLatest(GET_PROFILE_CERTIFICATION_REQUEST, getProfileCertificationData),
  takeLatest(POST_PROFILE_CERTIFICATION_REQUEST, profileCertificationData),
  takeLatest(GET_PROFILE_EXPERIENCE_REQUEST, getProfileExperienceData),
  takeLatest(POST_PROFILE_EXPERIENCE_REQUEST, profileExperienceData),
  takeLatest(DELETE_PROFILE_EXPERIENCE_REQUEST, deleteProfileExperienceData),
  takeLatest(GET_PROFILE_HOBBIES_REQUEST, getProfileHobbiesData),
  takeLatest(POST_PROFILE_HOBBIES_REQUEST, profileHobbiesData),
  takeLatest(POST_PROFILE_INFO_REQUEST, profileInfoData),
  takeLatest(POST_PROFILE_LANGUAGE_REQUEST, profileLanguageData),
  takeLatest(DELETE_PROFILE_LANGUAGE_REQUEST, deleteProfileLanguageData),
  takeLatest(GET_PROFILE_LANGUAGE_REQUEST, getProfileLanguageData),
  takeLatest(GET_PROFILE_PROJECT_REQUEST, getProfileProjectData),
  takeLatest(POST_PROFILE_PROJECT_REQUEST, profileProjectData),
  takeLatest(DELETE_PROFILE_PROJECT_REQUEST, deleteProfileProjectData),
  takeLatest(GET_PROFILE_SALARY_REQUEST, getProfileSalaryData),
  takeLatest(POST_PROFILE_SALARY_REQUEST, profileSalaryData),
  takeLatest(GET_PROFILE_SKILL_REQUEST, getProfileSkillData),
  takeLatest(POST_PROFILE_SKILL_REQUEST, profileSkillData),
  takeLatest(DELETE_PROFILE_SKILL_REQUEST, deleteProfileSkillData),
  takeLatest(GET_CANDIDATE_PROFILE_REQUEST, getCandidateProfile),
  takeLatest(GET_PROFILE_SOCIAL_MEDIA_REQUEST, getProfileSocialMediaData),
  takeLatest(POST_PROFILE_SOCIAL_MEDIA_REQUEST, profileSocialMediaData),
  takeLatest(POST_UPLOAD_RESUME_REQUEST, postUploadResume),
  takeLatest(POST_JOB_CONSENT_REQUEST, JobRecommendationConsent),
  takeLatest(GET_PROFILE_REQUIRED_INFO_REQUEST, profileRequiredInfoData)
])
