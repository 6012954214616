import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import CloseIcon from "@mui/icons-material/Close"
import dayjs from "dayjs"

import RichTextEditor from "../../../components/RichTextEditor"
import { convertDraftToHtml, convertHtmlToDraft } from "../../../utils/helper"
import UInput from "../../../components/UInput"
import { useForm } from "../../../hooks"
import {
  callNotesFailure,
  getCallNotesRequest,
  postCallNotesRequest
} from "./redux/action"
import Button from "../../../components/Button"
import { CircularProgress } from "@mui/material"
import USearch from "../../../components/USearch"

const CallNotes = ({
  beError,
  callNotes,
  clearBeError,
  requesting,
  postCallNotesRequesting,
  getCallNotes,
  postCallNotes,
  candidateData,
  jobData
}) => {
  const [selectedNote, setSelectedNote] = useState({})
  const [searchParam, setSearchParam] = useState("")

  useEffect(() => {
    getCallNotes({
      candidateId: candidateData?.candidate_id,
      jobId: jobData?.id
    })
  }, [getCallNotes, candidateData, jobData])

  const stateSchema = {
    body: {
      value: "",
      error: ""
    },
    title: {
      value: "",
      error: ""
    }
  }

  const validationStateSchema = {
    body: {
      required: true
    },
    title: {
      required: true
    }
  }

  const { state, handleOnChange, setState } = useForm(
    stateSchema,
    validationStateSchema
  )

  const handleCallNoteChange = newValue => {
    handleOnChange(
      "body",
      newValue.getCurrentContent().hasText() ? newValue : ""
    )
    clearError()
  }

  const handleTitleChange = e => {
    handleOnChange("title", e.target.value)
    clearError()
  }

  const selectCallNote = note => () => {
    setSelectedNote(note)
    setState({
      title: { ...stateSchema.title, value: note?.subject || "" },
      body: {
        ...stateSchema.body,
        value: convertHtmlToDraft(note?.body) || ""
      }
    })
    clearError()
  }

  const saveNote = () => {
    postCallNotes({
      candidateId: candidateData.candidate_id,
      jobId: jobData?.id,
      body: convertDraftToHtml(state.body.value),
      subject: state.title.value,
      id: selectedNote?.id
    })
  }

  const clearError = () => clearBeError()

  const searchNotes = inputValue => {
    setSearchParam(inputValue)
  }

  const filterCallNotes = () => {
    if (!searchParam) {
      return callNotes
    }

    return (
      callNotes?.filter(
        note =>
          note.subject?.includes(searchParam) ||
          note.body?.includes(searchParam)
      ) || []
    )
  }

  return (
    <div className="call-notes-section">
      <div className="call-notes-section__history">
        <div className="call-notes-section__history__search">
          <USearch
            placeholder="Search notes"
            hideButton={true}
            onClick={searchNotes}
          />
        </div>
        {requesting && (
          <div className="call-notes-section__history__not-found">
            <CircularProgress color="inherit" />
          </div>
        )}
        {!requesting && !callNotes.length && (
          <div className="call-notes-section__history__not-found">
            No notes found
          </div>
        )}
        <div className="call-notes-section__history__note__wrapper">
          {filterCallNotes().map(note => (
            <div
              className={`call-notes-section__history__note ${
                selectedNote?.id === note?.id
                  ? "call-notes-section__history__note--active"
                  : ""
              }`}
              key={note?.id}
              onClick={selectCallNote(note)}
            >
              <div className="title-row">
                <div className="title">{note?.subject}</div>
                <div className="edited-time">
                  {note?.updatedAt
                    ? dayjs(note?.updatedAt).format("MMM DD")
                    : dayjs(note?.createdAt).format("MMM DD")}
                </div>
              </div>
              <div className="body-row">
                <div
                  className="body"
                  dangerouslySetInnerHTML={{ __html: note?.body }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="call-notes-section__view">
        <div className="call-notes-section__view__action">
          {selectedNote?.id ? (
            <>
              Viewing <span>{selectedNote?.subject}</span>{" "}
              <CloseIcon
                className="action-clear"
                onClick={selectCallNote("")}
              />
            </>
          ) : (
            "Add Note"
          )}
        </div>
        <div className="call-notes-section__view__editor">
          <UInput
            placeholder="Title"
            value={state.title?.value}
            id="note-title"
            errorClass={
              beError?.title || state?.title?.error ? "opacity-1" : ""
            }
            errorText={beError?.title || state?.title?.error}
            inputError={beError?.title || state?.title?.error}
            onChange={handleTitleChange}
          />
          <RichTextEditor
            key={selectedNote?.id}
            className="rich-text-editor"
            onChange={handleCallNoteChange}
            placeholder="Description"
            defaultEditorState={state.body?.value}
            error={beError?.body || state.body?.error}
          />
          <div
            className={`error-message ${
              beError?.description || state.body.error ? "opacity-1" : ""
            }`}
          >
            {beError?.description || state.body.error}
          </div>
          {!selectedNote?.id && (
            <Button
              title="Save Note"
              className="action-save-note"
              onClick={saveNote}
              showSpinner={postCallNotesRequesting}
            />
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  callNotes: state.selectedProfile.callNotes,
  beError: state.selectedProfile.callNotesError,
  requesting: state.selectedProfile.callNotesRequesting,
  postCallNotesRequesting: state.selectedProfile.postCallNotesRequesting
})

const mapDispatchToProps = dispatch => ({
  getCallNotes: data => dispatch(getCallNotesRequest(data)),
  postCallNotes: data => dispatch(postCallNotesRequest(data)),
  clearBeError: data => dispatch(callNotesFailure(""))
})

export default connect(mapStateToProps, mapDispatchToProps)(CallNotes)
