import {
  GET_SELECTED_PROFILE_REQUEST,
  GET_SELECTED_PROFILE_SUCCESS,
  GET_SELECTED_PROFILE_FAILURE,
  GET_JOBS_APPLIED_REQUEST,
  GET_JOBS_APPLIED_SUCCESS,
  GET_JOBS_APPLIED_FAILURE,
  POST_JOB_STATUS_REQUEST,
  POST_JOB_STATUS_SUCCESS,
  POST_JOB_STATUS_FAILURE,
  GET_DOWNLOAD_PROFILE_REQUEST,
  GET_DOWNLOAD_PROFILE_SUCCESS,
  POST_CANDIDATE_TYPE_REQUEST,
  POST_CANDIDATE_TYPE_SUCCESS,
  GET_CALL_NOTES_REQUEST,
  POST_CALL_NOTES_REQUEST,
  CALL_NOTES_SUCCESS,
  CALL_NOTES_FAILURE
} from "./type"

// GET_CANDIDATE_PROFILE
export const getSelectedProfileRequest = data => {
  return {
    type: GET_SELECTED_PROFILE_REQUEST,
    data
  }
}

export const getSelectedProfileSuccess = data => ({
  type: GET_SELECTED_PROFILE_SUCCESS,
  data
})

export const getSelectedProfileFailure = data => ({
  type: GET_SELECTED_PROFILE_FAILURE,
  data
})

export const getJobsAppliedRequest = data => ({
  type: GET_JOBS_APPLIED_REQUEST,
  data
})

export const getJobsAppliedSuccess = data => ({
  type: GET_JOBS_APPLIED_SUCCESS,
  data
})

export const getJobsAppliedFailure = data => ({
  type: GET_JOBS_APPLIED_FAILURE,
  data
})

export const postJobStatusRequest = (data, callback) => ({
  type: POST_JOB_STATUS_REQUEST,
  data,
  callback
})

export const postJobStatusSuccess = data => ({
  type: POST_JOB_STATUS_SUCCESS,
  data
})

export const postJobStatusFailure = data => ({
  type: POST_JOB_STATUS_FAILURE,
  data
})

export const getDownloadProfileRequest = data => ({
  type: GET_DOWNLOAD_PROFILE_REQUEST,
  data
})

export const getDownloadProfileSuccess = data => ({
  type: GET_DOWNLOAD_PROFILE_SUCCESS,
  data
})

export const postCandidateTypeRequest = data => ({
  type: POST_CANDIDATE_TYPE_REQUEST,
  data
})

export const postCandidateTypeSuccess = data => ({
  type: POST_CANDIDATE_TYPE_SUCCESS,
  data
})

export const postCallNotesRequest = data => ({
  type: POST_CALL_NOTES_REQUEST,
  data
})

export const callNotesSuccess = data => ({
  type: CALL_NOTES_SUCCESS,
  data
})

export const callNotesFailure = data => ({
  type: CALL_NOTES_FAILURE,
  data
})

export const getCallNotesRequest = data => ({
  type: GET_CALL_NOTES_REQUEST,
  data
})
