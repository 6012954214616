// import Login from "./pages/AuthScreens/Login"
import Landing from "./pages/AuthScreens/Landing/index"
import Home from "./pages/CandidateScreens/Home"
import Profile from "./pages/CandidateScreens/Profile"
import Jobs from "./pages/CandidateScreens/Jobs"
import { Images } from "./theme/Images"

// Recruiter
import RecruiterProfile from "./pages/RecruiterScreen/RecruiterProfile"
import RecruiterEditProfile from "./pages/RecruiterScreen/RecruiterEditProfile"
import ChangePassword from "./pages/CommonScreens/ChangePassword"
import JobsList from "./pages/CommonScreens/JobsList"
import CreateRole from "./pages/CommonScreens/CreateRole"
import EditRole from "./pages/CommonScreens/EditRole"
import CandidateList from "./pages/RecruiterScreen/CandidateList"
import SelectedProfile from "./pages/CommonScreens/SelectedProfile"
import AdminTeams from "./pages/AdminScreens/Teams"
import AdminDashboard from "./pages/AdminScreens/Dashboard"
import AdminAllUsers from "./pages/AdminScreens/Users"
import AdminTeamMembers from "./pages/AdminScreens/TeamMembers"
import AuthScreens from "./pages/AuthScreens"
import JobDetails from "./pages/CommonScreens/JobDetails"
import {
  userTypeAdmin,
  userTypeCandidate,
  userTypeRecruiter
} from "./utils/constants"

export const routes = [
  // Auth Screen
  {
    component: Landing,
    path: "/landing",
    layout: "/auth",
    isShow: false
  },
  {
    component: AuthScreens,
    path: "/login",
    layout: "/auth",
    isShow: false
  },
  {
    component: AuthScreens,
    path: "/signup",
    layout: "/auth",
    isShow: false
  },
  {
    component: AuthScreens,
    path: "/recruiter-signup",
    layout: "/auth",
    isShow: false
  },
  {
    component: AuthScreens,
    path: "/forgot_password",
    layout: "/auth",
    isShow: false
  },
  {
    component: AuthScreens,
    path: "/confirm_password",
    layout: "/auth",
    isShow: false
  },
  {
    component: AuthScreens,
    path: "/microsoft",
    layout: "/auth",
    isShow: false
  },
  {
    component: AuthScreens,
    path: "/redirect",
    layout: "/microsoft-oauth",
    isShow: false,
    name: "Microsoft Login Redirect"
  },
  // Candidate Screens

  {
    name: "Find Jobs",
    component: Home,
    path: "/home",
    layout: `/${userTypeCandidate}`,
    iconSrc: Images.jobsIcon,
    activeIconSrc: Images.jobsIconFilled,
    isShow: userTypeCandidate
  },
  {
    name: "My Jobs",
    component: Jobs,
    path: "/jobs/my_jobs",
    urlParam: "/jobs/:jobSectionType",
    layout: `/${userTypeCandidate}`,
    iconSrc: Images.myJobsIcon,
    activeIconSrc: Images.myJobsIconFilled,
    isShow: userTypeCandidate,
    subMenu: [
      {
        name: "Applied",
        section: "candidate-applied-jobs"
      },
      {
        name: "Saved",
        section: "candidate-saved-jobs"
      },
      {
        name: "Interviewing",
        section: "candidate-interviewing-jobs"
      },
      {
        name: "Offered",
        section: "candidate-offered-jobs"
      },
      {
        name: "Hired",
        section: "candidate-hired-jobs"
      },
      {
        name: "Rejected",
        section: "candidate-rejected-jobs"
      },
      {
        name: "Archived",
        section: "candidate-archived-jobs"
      }
    ]
  },
  {
    name: "Profile",
    component: Profile,
    path: "/profile",
    layout: `/${userTypeCandidate}`,
    iconSrc: Images.profileIcon,
    activeIconSrc: Images.profileIconFilled,
    isShow: userTypeCandidate,
    subMenu: [
      {
        name: "Skills",
        section: "candidate-skills-section"
      },
      {
        name: "Experience",
        section: "candidate-experience-section"
      },
      {
        name: "Certifications",
        section: "candidate-certificate-section"
      },
      {
        name: "Social Media",
        section: "candidate-social-media-section"
      },
      {
        name: "Education",
        section: "candidate-education-section"
      },
      {
        name: "Languages",
        section: "candidate-language-section"
      },
      {
        name: "Projects",
        section: "candidate-projects-section"
      },
      {
        name: "Hobbies",
        section: "candidate-hobbies-section"
      }
    ]
  },
  {
    name: "Change Password",
    component: ChangePassword,
    path: "/change_password",
    layout: `/${userTypeCandidate}`,
    isShow: false
  },
  {
    name: "Job Details",
    component: JobDetails,
    path: "/job-details/:jobId",
    layout: `/${userTypeCandidate}`,
    iconSrc: Images.jobsIcon,
    activeIconSrc: Images.jobsIconFilled,
    isShow: false
  },

  // Recruiter Screens
  {
    name: "Create Role",
    component: CreateRole,
    path: "/jobs/create",
    layout: `/${userTypeRecruiter}`,
    isShow: false
  },
  {
    name: "Edit Role",
    component: EditRole,
    path: "/jobs/edit/:jobId",
    layout: `/${userTypeRecruiter}`,
    isShow: false
  },
  {
    name: "RecruiterEditProfile",
    component: RecruiterEditProfile,
    path: "/profile/edit",
    layout: `/${userTypeRecruiter}`,
    isShow: false
  },
  {
    name: "Profile",
    component: RecruiterProfile,
    path: "/profile",
    layout: `/${userTypeRecruiter}`,
    isShow: false
  },
  {
    name: "My Jobs",
    component: JobsList,
    path: "/jobs/my_jobs",
    urlParam: "/jobs/:jobSectionType",
    layout: `/${userTypeRecruiter}`,
    iconSrc: Images.jobsIcon,
    activeIconSrc: Images.jobsIconFilled,
    isShow: userTypeRecruiter,
    subMenu: [
      {
        name: "Posted Jobs",
        section: "recruiter-posted-jobs"
      },
      {
        name: "Draft Jobs",
        section: "recruiter-saved-jobs"
      },
      {
        name: "Closed Jobs",
        section: "recruiter-closed-jobs"
      }
    ]
  },
  {
    name: "All Jobs",
    component: JobsList,
    path: "/jobs/all_jobs",
    urlParam: "/jobs/:jobSectionType",
    layout: `/${userTypeRecruiter}`,
    iconSrc: Images.jobsIcon,
    activeIconSrc: Images.jobsIconFilled,
    isShow: userTypeRecruiter
  },
  {
    name: "All Candidates",
    component: CandidateList,
    path: "/candidate_list/:jobId",
    layout: `/${userTypeRecruiter}`,
    iconSrc: Images.profileIcon,
    activeIconSrc: Images.profileIconFilled,
    isShow: userTypeRecruiter
  },
  {
    // TODO: add profileId in the URL to identify the user
    name: "Profile",
    component: SelectedProfile,
    path: "/selected_profile", // TODO use profile id
    layout: `/${userTypeRecruiter}`,
    isShow: false
  },
  {
    name: "Job Details",
    component: JobDetails,
    path: "/job-details/:jobId",
    layout: `/${userTypeRecruiter}`,
    iconSrc: Images.jobsIcon,
    activeIconSrc: Images.jobsIconFilled,
    isShow: false
  },
  {
    name: "Change Password",
    component: ChangePassword,
    path: "/change_password",
    layout: `/${userTypeRecruiter}`,
    isShow: false
  },

  //Admin Screens
  {
    name: "Dashboard",
    component: AdminDashboard,
    path: "/dashboard",
    layout: `/${userTypeAdmin}`,
    iconSrc: Images.dashboardIcon,
    activeIconSrc: Images.dashboardIconFilled,
    isShow: userTypeAdmin
  },
  {
    name: "All Users",
    component: AdminAllUsers,
    path: "/all-users",
    layout: `/${userTypeAdmin}`,
    iconSrc: Images.usersIcon,
    activeIconSrc: Images.usersIconFilled,
    isShow: userTypeAdmin
  },
  {
    name: "Candidate Profile",
    component: SelectedProfile,
    path: "/candidate-user-profile",
    layout: `/${userTypeAdmin}`,
    isShow: false
  },
  {
    name: "Create Role",
    component: CreateRole,
    path: "/jobs/create",
    layout: `/${userTypeAdmin}`,
    isShow: false
  },
  {
    name: "Edit Role",
    component: EditRole,
    path: "/jobs/edit/:jobId",
    layout: `/${userTypeAdmin}`,
    isShow: false
  },
  {
    name: "My Jobs",
    component: JobsList,
    path: "/jobs/my_jobs",
    urlParam: "/jobs/:jobSectionType",
    layout: `/${userTypeAdmin}`,
    iconSrc: Images.jobsIcon,
    activeIconSrc: Images.jobsIconFilled,
    isShow: userTypeAdmin,
    subMenu: [
      {
        name: "Posted Jobs",
        section: "admin-posted-jobs"
      },
      {
        name: "Draft Jobs",
        section: "admin-saved-jobs"
      },
      {
        name: "Closed Jobs",
        section: "admin-closed-jobs"
      }
    ]
  },
  {
    name: "All Jobs",
    component: JobsList,
    path: "/jobs/all_jobs",
    urlParam: "/jobs/:jobSectionType",
    layout: `/${userTypeAdmin}`,
    iconSrc: Images.jobsIcon,
    activeIconSrc: Images.jobsIconFilled,
    isShow: userTypeAdmin
  },
  {
    name: "Job Details",
    component: JobDetails,
    path: "/job-details/:jobId",
    layout: `/${userTypeAdmin}`,
    iconSrc: Images.jobsIcon,
    activeIconSrc: Images.jobsIconFilled,
    isShow: false
  },
  // TODO bug when navigating to All Candidates from side menu, jobId is literally ":jobId"
  {
    name: "All Candidates",
    component: CandidateList,
    path: "/candidate_list/:jobId",
    layout: `/${userTypeAdmin}`,
    iconSrc: Images.profileIcon,
    activeIconSrc: Images.profileIconFilled,
    isShow: userTypeAdmin
  },
  {
    name: "Profile",
    component: SelectedProfile,
    path: "/selected_profile", // TODO use profile id
    layout: `/${userTypeAdmin}`,
    isShow: false
  },
  {
    name: "Team Members",
    component: AdminTeamMembers,
    path: "/teams/members",
    layout: `/${userTypeAdmin}`,
    isShow: false
  },
  {
    name: "Teams",
    component: AdminTeams,
    path: "/teams",
    layout: `/${userTypeAdmin}`,
    iconSrc: Images.adminTeams,
    activeIconSrc: Images.adminTeamsFilled,
    isShow: userTypeAdmin
  },
  {
    name: "Change Password",
    component: ChangePassword,
    path: "/change_password",
    layout: `/${userTypeAdmin}`,
    isShow: false
  }
]
