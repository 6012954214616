import { all, call, put, takeLatest } from "redux-saga/effects"

// config
import { BASE_URL } from "../../../../config/app"
// utils
import XHR from "../../../../utils/XHR"
// types
import {
  GET_SELECTED_PROFILE_REQUEST,
  GET_JOBS_APPLIED_REQUEST,
  POST_JOB_STATUS_REQUEST,
  GET_DOWNLOAD_PROFILE_REQUEST,
  POST_CANDIDATE_TYPE_REQUEST,
  GET_CALL_NOTES_REQUEST,
  POST_CALL_NOTES_REQUEST
} from "./type"
// actions
import {
  getSelectedProfileSuccess,
  getSelectedProfileFailure,
  getJobsAppliedSuccess,
  getJobsAppliedFailure,
  postJobStatusSuccess,
  postJobStatusFailure,
  getDownloadProfileSuccess,
  getSelectedProfileRequest,
  callNotesFailure,
  callNotesSuccess,
  getCallNotesRequest
} from "./action"
import toast from "react-hot-toast"
import { downloadExcelFile } from "../../../../utils/commonFunction"
// GET PROFILE CANDIDATe

const getCandidateProfileAPI = async data => {
  const URL = `${BASE_URL}api/v1/recruiter/candidate-profile-view/?candidate_id=${data?.candidate_id}`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getCandidateProfile({ data }) {
  try {
    const response = yield call(getCandidateProfileAPI, data)
    yield put(getSelectedProfileSuccess(response.data))
    yield put(getSelectedProfileFailure(false))
  } catch (e) {
    const { response } = e
    yield put(getSelectedProfileFailure(response.data))
  }
}

const getJobsAppliedAPI = async (
  data = { page: 1, limit: 10, sortBy: "id", sortDir: "desc", candidateId: "" }
) => {
  const { page, limit, sortBy, sortDir, candidateId } = data
  const queryString = `?page=${page}&limit=${limit}&sortBy=${sortBy}&sortDir=${sortDir}&candidate_id=${candidateId}`
  const URL = `${BASE_URL}api/v1/recruiter/candidateJobs/${queryString}`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getJobsApplied({ data }) {
  try {
    const response = yield call(getJobsAppliedAPI, data)
    yield put(getJobsAppliedSuccess(response.data))
    yield put(getJobsAppliedFailure(false))
  } catch (e) {
    const { response } = e
    yield put(getJobsAppliedFailure(response.data))
  }
}

const postJobStatusAPI = async data => {
  const URL = `${BASE_URL}api/v1/recruiter/job-application-status/${data?.id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "PUT",
    data: {
      application_status: data?.status
    }
  }
  return XHR(URL, options)
}

function* postJobStatus({ data, callback }) {
  try {
    const response = yield call(postJobStatusAPI, data)
    yield put(postJobStatusSuccess(response.data))
    yield put(postJobStatusFailure(false))
    callback && callback(response.data)
    toast.success("Successfully updated application status.")
  } catch (e) {
    const { response } = e
    yield put(postJobStatusFailure(response.data))
    toast.error("Some error occurred while updating the application status.")
  }
}

const getDownloadProfileAPI = async data => {
  const URL = `${BASE_URL}api/v1/recruiter/download-resume/${data?.candidate_id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET",
    responseType: "blob"
  }

  return XHR(URL, options)
}

function* getDownloadProfile({ data }) {
  try {
    const response = yield call(getDownloadProfileAPI, data)
    yield put(getDownloadProfileSuccess(response.data))
    const contentDispositionHeader = response.headers["content-disposition"]
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    const filenameMatch = filenameRegex.exec(contentDispositionHeader)
    const filename = filenameMatch && filenameMatch[1].replace(/['"]/g, "")
    downloadExcelFile(response.data, {
      contentTypeHeader: response.headers["Content-Type"],
      fileName: filename
    })
  } catch (e) {
    const { response } = e

    yield put(getDownloadProfileSuccess(response.data))
    toast.error(
      response?.data?.error
        ? response.data.error
        : "Some error occurred while downloading the resume."
    )
  }
}

async function postCandidateTypeAPI(data) {
  const URL = `${BASE_URL}api/v1/recruiter/candidateType/${
    data?.id ? data?.id + "/" : ""
  }`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: data?.id ? "PUT" : "POST",
    data
  }
  return XHR(URL, options)
}

function* postCandidateType({ data }) {
  try {
    const response = yield call(postCandidateTypeAPI, data)
    yield put(postJobStatusFailure(response.data))
    yield put(getSelectedProfileRequest({ candidate_id: data?.candidateId }))
  } catch (e) {
    const { response } = e
    yield put(postJobStatusFailure(response.data))
    toast.error(
      "An error occurred while updating the candidate type. Please try again later"
    )
  }
}

const getCallNotesAPI = async data => {
  const URL = `${BASE_URL}api/v1/recruiter/call-notes/?candidateId=${data?.candidateId}&jobId=${data?.jobId}`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getCallNotesData({ data }) {
  try {
    const response = yield call(getCallNotesAPI, data)
    yield put(callNotesSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(callNotesFailure(response.data))
  }
}

async function postCallNotesAPI(data) {
  const URL = `${BASE_URL}api/v1/recruiter/call-notes/${
    data?.id ? data?.id + "/" : ""
  }`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: data?.id ? "PUT" : "POST",
    data
  }
  return XHR(URL, options)
}

function* postCallNotes({ data }) {
  try {
    const response = yield call(postCallNotesAPI, data)
    yield put(getCallNotesRequest(data))
  } catch (e) {
    const { response } = e
    yield put(callNotesFailure(response.data))
    toast.error(
      `An error occurred while ${
        data?.id ? "updating" : "adding"
      } the call notes. Please try again later`
    )
  }
}

export default all([
  takeLatest(GET_SELECTED_PROFILE_REQUEST, getCandidateProfile),
  takeLatest(GET_JOBS_APPLIED_REQUEST, getJobsApplied),
  takeLatest(POST_JOB_STATUS_REQUEST, postJobStatus),
  takeLatest(GET_DOWNLOAD_PROFILE_REQUEST, getDownloadProfile),
  takeLatest(POST_CANDIDATE_TYPE_REQUEST, postCandidateType),
  takeLatest(GET_CALL_NOTES_REQUEST, getCallNotesData),
  takeLatest(POST_CALL_NOTES_REQUEST, postCallNotes)
])
