import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import React, { useState, useEffect, useCallback } from "react"

import Button from "../../../components/Button"
import ConsentModal from "../../../components/AuthModals/ConsentModal"
import PasswordInput from "../../../components/PasswordInput"
import PrivacyTermsModal from "../../../components/PrivacyTermsModal"
import SocialLogin from "../../../components/SocialLogin"
import StrengthMeter from "../../../components/StrengthMeter"
import UInput from "../../../components/UInput"
import { useForm } from "../../../hooks"

import {
    signupRequest,
    signupRequestFailure,
    signupRequestSuccess,
    googleSignupRequest,
    googleSignupRequestSuccess,
    googleSignupRequestFailure,
    microsoftSignupRequest,
    microsoftSignupRequestSuccess,
    microsoftSignupRequestFailure
  } from "./redux/action"

  import {
    googleLoginRequest,
    googleLoginRequestFailure,
    microsoftLoginRequest,
    microsoftLoginRequestFailure,
    loginRequest,
    loginRequestFailure
  } from "../Login/redux/action"


const SignUpRedirect = props => {
    const {
        loginRequest,
        error,
        requesting,
        loginRequestFailure,
        loginRequestGoogle,
        loginRequestGoogleFailure,
        loginRequestMicrosoft,
        loginRequestMicrosoftFailure,
        setEmail
    } = props

    const history = useHistory()

    useEffect(() => {
        loginRequestFailure(false)
    }, [])

    const onLoginError = useCallback(
        (provider, error) => {},
        [loginRequestGoogleFailure, loginRequestMicrosoftFailure]
    )

    const onLoginSuccess = useCallback(
        (provider, userData) => {
        provider === "google" && loginRequestGoogle(userData)
        provider === "microsoft" && loginRequestMicrosoft(userData)
        },
        [localStorage, loginRequestGoogle, loginRequestMicrosoft]
    )

    return (
        <>
        <div>Redirect Hello</div>
        <div className="other-sign-in-options">
            <SocialLogin
                className="sign-in-options"
                onLoginSuccess={onLoginSuccess}
                onLoginError={onLoginError}
                pretext="Sign In"
                userType="recruiter"
            />
            </div>
        </>
    )
}

const mapStateToProps = state => ({
    loginInfo: state.login.loginInfo,
    error: state.login.error,
    requesting: state.login.requesting,
    currentUserInfo: state.login.currentUserInfo
  })

const mapDispatchToProps = dispatch => ({
    loginRequest: data => dispatch(loginRequest(data)),
    loginRequestFailure: data => dispatch(loginRequestFailure(data)),
    loginRequestGoogle: data => dispatch(googleLoginRequest(data)),
    loginRequestMicrosoft: data => dispatch(microsoftLoginRequest(data)),
    loginRequestGoogleFailure: data => dispatch(googleLoginRequestFailure(data)),
    loginRequestMicrosoftFailure: data =>
        dispatch(microsoftLoginRequestFailure(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SignUpRedirect)
