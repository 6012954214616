import Modal from "react-bootstrap/Modal"
import React from "react"
import { connect } from "react-redux"
import { Images } from "../../../theme/Images"
import Image from "../../Image"
import { displayJobsModal } from "../../../pages/CandidateScreens/Home/redux/action"
import { getCurrencySymbol, getFormattedLocation } from "../../../utils/helper"
import dayjs from "dayjs"
import { getEmploymentTypeLabel } from "../../../utils/helper"
import "./style.scss"
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

const JobsModal = props => {
  const { showJobsModal, jobsData, dispatchCloseModal } = props

  const closeModal = () => {
    dispatchCloseModal(false)
  }

  return (
    <Modal
      show={showJobsModal}
      setShow={closeModal}
      centered
      className="auth-modal team-modal"
    >
      <Modal.Header>
        <Modal.Title>
          <div className="job-title">
            <div className="title">{jobsData[0]?.title}</div>
            <div className="close-icon" onClick={closeModal}>
              <Image src={Images.closeIcon} />
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="job-information">
          <div className="published-by">
            <div className="location">
              <Image src={Images.location_dark} />
              <div className="location-name">
                {getFormattedLocation(jobsData[0]?.locations)}
              </div>
            </div>
          </div>
          <div className="job-info">
            {`${getEmploymentTypeLabel(jobsData[0]?.employment_type)} - Posted  ${dayjs(
              jobsData[0]?.job_created_date
            )
              .endOf("day")
              .fromNow()}`}
          </div>

          <div className="description">
            <div className="head">Job Details</div>
            <div
              className="job-description"
              dangerouslySetInnerHTML={{
                __html: jobsData[0]?.description
              }}
            ></div>
          </div>
          <div className="skills">
            <div className="head">Skills and Expertise</div>
            <div className="expertise">
              {jobsData[0]?.skills?.length > 0 &&
                jobsData[0]?.skills.map((skill, index) => {
                  return (
                    <div className="job-skill" key={`${skill?.label}-${index}`}>
                      {skill?.label}
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

const mapDispatchToProps = dispatch => ({
  dispatchCloseModal: data => dispatch(displayJobsModal(data))
})

export default connect(null, mapDispatchToProps)(JobsModal)
