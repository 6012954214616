import { combineReducers } from "redux"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"

import login from "../pages/AuthScreens/Login/redux/reducer"
import signup from "../pages/AuthScreens/SignUp/redux/reducer"
import forgotPassword from "../pages/AuthScreens/ForgotPassword/redux/reducer"
import confirmPassword from "../pages/AuthScreens/ConfirmPassword/redux/reducer"
import profile from "../pages/CandidateScreens/Profile/redux/reducer"
import candidateJobs from "../pages/CandidateScreens/Home/redux/reducer"
import changePassword from "../pages/CommonScreens/ChangePassword/redux/reducer"
import recruiterprofile from "../pages/RecruiterScreen/RecruiterEditProfile/redux/reducer"
import getprofileData from "../pages/RecruiterScreen/RecruiterProfile/redux/reducer"
import createRole from "../pages/CommonScreens/CreateRole/redux/reducer"
import jobDetails from "../pages/CommonScreens/JobDetails/redux/reducer"
import recruiterJobs from "../pages/CommonScreens/JobsList/redux/reducer"
import candidateList from "../pages/RecruiterScreen/CandidateList/redux/reducer"
import selectedProfile from "../pages/CommonScreens/SelectedProfile/redux/reducer"
import adminTeams from "../pages/AdminScreens/Teams/redux/reducer"
import adminDashboard from "../pages/AdminScreens/Dashboard/redux/reducer"
import adminUsers from "../pages/AdminScreens/Users/redux/reducer"
import adminTeamMembers from "../pages/AdminScreens/TeamMembers/redux/reducer"
import metaData from "../hooks/MetaData/redux/reducer"
import locationData from "../hooks/Location/redux/reducer"
import conceptData from "../hooks/Concept/redux/reducer"

const presistConfig = {
  key: "login",
  storage,
  whitelist: ["loginInfo", "accessToken"]
}
export const combinedReducers = combineReducers({
  login: persistReducer(presistConfig, login),
  signup,
  forgotPassword,
  confirmPassword,
  profile,
  candidateJobs,
  recruiterprofile,
  selectedProfile,
  getprofileData,
  createRole,
  jobDetails,
  recruiterJobs,
  candidateList,
  changePassword,
  adminTeams,
  adminDashboard,
  adminUsers,
  adminTeamMembers,
  metaData,
  locationData,
  conceptData
})
