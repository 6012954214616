import { all, call, put, takeLatest } from "redux-saga/effects"
// config
import { BASE_URL } from "../../../../config/app"
// utils
import XHR from "../../../../utils/XHR"
// types
import { GET_PROFILE_DATA_REQUEST } from "./type"
// actions
import { getProfileDataSuccess } from "./action"

async function GetProfileDataAPI() {
  const URL = `${BASE_URL}api/v1/recruiter/profile/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* ProfileData() {
  try {
    const response = yield call(GetProfileDataAPI)
    yield put(
      getProfileDataSuccess(
        Array.isArray(response?.data) ? response?.data[0] : response?.data
      )
    )
  } catch (e) {
    const { response } = e

    // yield put(profileProfileDataFailure(response.data))
  }
}
export default all([takeLatest(GET_PROFILE_DATA_REQUEST, ProfileData)])
