import React, { useState, useEffect, useMemo } from "react"
import { useHistory } from "react-router-dom"
import { connect } from "react-redux"
import Chip from "@mui/material/Chip"
import Stack from "@mui/material/Stack"
import dayjs from "dayjs"

import RichTextEditor from "../../../components/RichTextEditor"
import Button from "../../../components/Button"
import UDatePicker from "../../../components/UDatePicker"
import UInput from "../../../components/UInput"
import EditModal from "../../../components/CandidateProfileEditModal"
import validator from "../../../utils/validation"
import SkillCard from "../../../components/ProfileEditedCards/SkillCard"
import USelect from "../../../components/USelect"
import {
  getAssigneesRequest,
  getSkillsScoreRequest,
  getSkillsScoreSuccess,
  postCreateNewRole,
  postCreateNewRoleFailure
} from "./redux/action"
import { useMetaData, useForm, useLocation } from "../../../hooks"
import URadio from "../../../components/URadio"
import {
  convertDraftToHtml,
  convertHtmlToDraft,
  dropdownFormat,
  formatSkills,
  formatSkillsForJobs,
  isAdmin,
  isRecruiter,
  prepareDropdownList,
  getKeyToLabelData
} from "../../../utils/helper"

import "./style.scss"

const CreateRole = ({ onChange, ...props }) => {
  const {
    postCreateNewRoleDispatch,
    postCreateNewRoleFailureDispatch,
    getAssigneesDispatch,
    requesting,
    error,
    assigneesList,
    loginInfo,
    userType,
    getSkillsScore,
    skillsScore,
    skillsScoreRequesting,
    clearSkillScore,
    jobDetails
  } = props
  const {
    currencyList,
    employmentTypeList,
    fillabilityLevelList,
    experienceLevelList,
    jobCategoryList,
    compensationPeriodList
  } = useMetaData()
  const history = useHistory()
  const { location } = history
  const selectedJobDetails = jobDetails?._id ? jobDetails : location?.formState?.selectedJobDetails
  const [show, setShow] = useState(false)
  const [currentModal, setCurrentModal] = useState({})
  const [locationInput, setLocationInput] = useState("")
  const {
    fetchLocationList,
    fetchLocationInfo,
    locationOptions,
    locationDetails,
    locationListRequesting,
    setLocationOptions
  } = useLocation({ type: "(cities)" })

  const employmentTypeKeyToLabel = getKeyToLabelData(employmentTypeList)
  const jobCategoryListKeyToLabel = getKeyToLabelData(jobCategoryList)

  useEffect(() => {
    selectedJobDetails?.skills?.length &&
      getSkillsScore({ skills: selectedJobDetails?.skills })
  }, [selectedJobDetails?.skills, getSkillsScore])

  useEffect(() => {
    setLocationOptions([])
  }, [setLocationOptions])

  useEffect(() => {
    postCreateNewRoleFailureDispatch(false)
  }, [postCreateNewRoleFailureDispatch])

  useEffect(() => {
    !assigneesList?.length && getAssigneesDispatch()
  }, [assigneesList, getAssigneesDispatch])

  useEffect(() => {
    if (
      selectedJobDetails?.place_id &&
      (!locationDetails?.length ||
        selectedJobDetails?.place_id !== locationDetails[0]?.place_id)
    ) {
      setLocationOptions([])
      fetchLocationInfo(selectedJobDetails?.place_id)
    }
  }, [fetchLocationInfo, selectedJobDetails?.place_id])

  useEffect(() => {
    if (
      locationDetails?.length > 0 &&
      locationDetails[0]?.place_id !== formState.placeId?.value
    ) {
      handleInputChange("placeId")({
        target: { value: locationDetails[0]?.place_id }
      })
    }
  }, [locationDetails])

  const assigneesOptions = useMemo(() => {
    const optionsList = isAdmin(userType)
      ? [...assigneesList, { email: loginInfo?.user?.email }]
      : assigneesList
    return prepareDropdownList(optionsList?.map(item => item.email))
  }, [assigneesList, loginInfo, userType])

  const handleDescriptionChange = newValue => {
    handleOnChange(
      "description",
      newValue.getCurrentContent().hasText() ? newValue : ""
    )
  }

  const stateSchema = {
    skills: {
      value: formatSkills(selectedJobDetails?.skills || []),
      error: ""
    },
    title: {
      value: selectedJobDetails?.title || "",
      error: ""
    },
    client_company: {
      value: selectedJobDetails?.client_company || '',
      error: ""
    },
    description: {
      value: convertHtmlToDraft(selectedJobDetails?.description),
      error: ""
    },
    fillability: {
      value: selectedJobDetails?.fillability || '',
      error: ""
    },
    minimum_compensation: {
      value: selectedJobDetails?.min_compensation || '',
      error: ""
    },
    maximum_compensation: {
      value: selectedJobDetails?.max_compensation || '',
      error: ""
    },

    compensation_period: {
      value: selectedJobDetails?.compensation_period
        ? dropdownFormat(selectedJobDetails?.compensation_period)
        : null,
      error: ""
    },
    location: {
      value: selectedJobDetails?.locations?.length
        ? selectedJobDetails?.locations[0]?.formatted_address
        : "",
      error: ""
    },
    startDate: {
      value: selectedJobDetails?.start_date,
      error: ""
    },
    endDate: {
      value: selectedJobDetails?.end_date,
      error: ""
    },
    owner: {
      value: dropdownFormat(
        selectedJobDetails?.owner || loginInfo?.user?.email
      ),
      error: ""
    },
    assignees: {
      value: selectedJobDetails?.assignees
        ? dropdownFormat(selectedJobDetails?.assignees)
        : null,
      error: ""
    },
    employment_type: {
      value: selectedJobDetails?.employment_type
        ? dropdownFormat(selectedJobDetails?.employment_type)
        : null,
      error: ""
    },
    experience_level: {
      value: selectedJobDetails?.experience_level
        ? experienceLevelList?.find(
            item => item?.key === selectedJobDetails?.experience_level
          )
        : null,
      error: ""
    },
    currency: {
      value: selectedJobDetails?.currency
        ? dropdownFormat(selectedJobDetails?.currency)
        : null,
      error: ""
    },
    job_category: {
      value: selectedJobDetails?.job_category
        ? dropdownFormat(selectedJobDetails?.job_category)
        : null,
      error: ""
    },
    job_type: {
      value: selectedJobDetails?.job_type || null,
      error: ""
    },
    placeId: {
      value: selectedJobDetails?.place_id || '',
      error: ""
    }
  }
  const validationStateSchema = {
    skills: {
      required: true,
      validator: validator.checkLength("Please add at least 5 skills", 5)
    },
    title: {
      required: true
    },
    client_company: {
      required: true
    },
    startDate: {
      required: false
    },
    endDate: {
      required: false
    },
    description: {
      required: true
    },
    fillability: {
      required: true
    },
    minimum_compensation: {
      required: false,
      validator: validator.numeric
    },
    maximum_compensation: {
      required: false,
      validator: validator.numeric
    },
    compensation_period: {
      required: false
    },
    location: {
      required: false
    },
    placeId: {
      required: false
    },
    owner: {
      required: true
    },
    assignees: {
      required: true
    },
    employment_type: {
      required: true
    },
    experience_level: {
      required: false
    },
    currency: {
      required: false
    },
    job_category: {
      required: true
    },
    job_type: {
      required: true
    }
  }

  const formSchema = useForm(
    stateSchema,
    validationStateSchema
  )

  const {handleOnChange, handleOnSubmit } = formSchema
  const formState = formSchema.state

  useEffect(() => {
    skillsScore?.length &&
      handleOnChange("skills", formatSkills([...skillsScore]))

    return () => clearSkillScore()
  }, [skillsScore, clearSkillScore])

  const handleInputChange = field => e => {
    handleOnChange(field, e.target.value)
  }

  const handleDropdownChange = field => (e, option) => {
    handleOnChange(field, option)
    if (field === "location") {
      fetchLocationInfo(option?.place_id)
    }
  }

  const handleFillabilityChange = (field, value) => () => {
    handleOnChange("fillability", value)
  }

  const handleDateChange = field => value => {
    handleOnChange(field, value)
  }

  const handleAddSkillClick = id => {
    setShow(true)
    setCurrentModal({ section: "profile-skills", id })
  }

  const handleSkillsChange = skillsInfo => {
    const currentSkills = formState?.skills?.value?.length ? formState?.skills?.value : []
    const skillsLength = currentSkills?.length
    let skillsData = []

    setShow(false)
    if (skillsInfo?.deletedId) {
      skillsData =
      currentSkills?.filter(item => item?.id !== skillsInfo?.deletedId) || []
      return handleOnChange("skills", skillsData)
    } else if (skillsInfo?.id) {
      currentSkills[skillsInfo?.id - 1] = skillsInfo
      skillsData = currentSkills
    } else {
      const newSkill = [{
        ...skillsInfo,
        id: skillsLength + 1
      }]
      skillsData = [...currentSkills, ...newSkill]
    }

    handleOnChange("skills", skillsData)
    getSkillsScore({ skills: formatSkillsForJobs(skillsData) })
  }

  const clearError = () => postCreateNewRoleFailureDispatch(false)

  const onSubmit = status => () => {
    if (!handleOnSubmit()) {
      return false
    }

    const data = {
      id: selectedJobDetails?.id,
      skills: formatSkillsForJobs(formState.skills.value),
      title: formState.title.value,
      client_company: formState.client_company.value,
      description: convertDraftToHtml(formState?.description?.value),
      fillability: formState.fillability.value,
      min_compensation: !!formState.minimum_compensation.value ? formState.minimum_compensation.value : null,
      max_compensation: !!formState.maximum_compensation.value ? formState.maximum_compensation.value : null,
      compensation_period: formState?.compensation_period?.value?.key,
      locations: locationDetails,
      owner: formState.owner.value?.key,
      assignees: formState.assignees.value?.key,
      employment_type: formState?.employment_type?.value?.key,
      experience_level: formState?.experience_level?.value?.key,
      currency: formState?.currency?.value?.key,
      job_category: formState?.job_category?.value?.key,
      start_date:
        formState?.startDate?.value &&
        dayjs(formState?.startDate?.value).format("YYYY-MM-DD"),
      end_date:
        formState?.endDate?.value &&
        dayjs(formState?.endDate?.value).format("YYYY-MM-DD"),
      job_type: formState?.job_type?.value,
      userType,
      status
    }
    postCreateNewRoleDispatch(data, history)
  }

  const goToMyJobs = () => {
    history.goBack()
  }

  const handleLocationInputChange = (e, value) => {
    setLocationInput(value)
    typeof value === "string" && value?.length >= 3
      ? fetchLocationList(value)
      : setLocationOptions([])
  }

  const isEditing = () => {
    return !!selectedJobDetails?._id
  }

  return (
    <>
      <div className="create-role-section">
        <div className="heading-div flex-justify-between">
          <h2>{selectedJobDetails?.id ? "Edit Role" : "Create New Role"}</h2>
        </div>
        <div className="create-role-form-section">
          <div className="create-role-form card">
            <div className="role-info-section">
              <label className="section-header">Job Details</label>
              <UInput
                placeholder="Title"
                value={formState.title.value}
                id={error?.title ? "outlined-error-helper-text" : ""}
                errorClass={
                  error?.title || formState.title.error ? "opacity-1" : ""
                }
                errorText={error?.title || formState.title.error}
                inputError={error?.title || formState.title.error}
                onChange={handleInputChange("title")}
                onFocus={clearError}
              />
              <UInput
                placeholder="Job Reference"
                value={formState.client_company.value}
                id={error?.client_company ? "outlined-error-helper-text" : ""}
                errorClass={
                  error?.client_company || formState.client_company.error
                    ? "opacity-1"
                    : ""
                }
                errorText={error?.client_company || formState.client_company.error}
                inputError={error?.client_company || formState.client_company.error}
                onChange={handleInputChange("client_company")}
                onFocus={clearError}
              />
              <div className="date-fields-row">
                <div className="date-fields-wrapper">
                  <UDatePicker
                    placeholderValue={"Start Date"}
                    value={formState?.startDate?.value || null}
                    onChange={handleDateChange("startDate")}
                    error={error?.start_date || formState?.startDate?.error}
                  />
                  <div
                    className={`error-message
            ${error?.start_date || formState?.startDate?.error ? "opacity-1" : ""}`}
                  >
                    {error?.start_date || formState?.startDate?.error}
                  </div>
                </div>
                <div className="date-fields-wrapper">
                  <UDatePicker
                    placeholderValue={"End Date"}
                    value={formState?.endDate?.value || null}
                    onChange={handleDateChange("endDate")}
                    error={error?.end_date || formState?.endDate?.error}
                  />
                  <div
                    className={`error-message
            ${error?.end_date || formState?.endDate?.error ? "opacity-1" : ""}`}
                  >
                    {error?.end_date || formState?.endDate?.error}
                  </div>
                </div>
              </div>
              <div className="text-editor-wrapper">
                <label className="field-label">Description</label>
                <RichTextEditor
                  className="rich-text-editor"
                  onChange={handleDescriptionChange}
                  placeholder="Description"
                  defaultEditorState={formState.description.value}
                  error={error?.description || formState.description.error}
                />
                <div
                  className={`error-message ${
                    error?.description || formState.description.error
                      ? "opacity-1"
                      : ""
                  }`}
                >
                  {error?.description || formState.description.error}
                </div>
              </div>
              <USelect
                placeholderLabel="Search a Location"
                options={locationOptions}
                value={formState.location.value || ""}
                onChange={handleDropdownChange("location")}
                onInputChange={handleLocationInputChange}
                onFocus={clearError}
                className={
                  error?.location || formState.placeId?.error
                    ? "error-field-wrapper"
                    : ""
                }
                inputError={error?.location || formState.placeId?.error}
                noOptionsText={
                  locationInput
                    ? "No locations found"
                    : "Type to search location"
                }
                locationData={true}
                loading={locationListRequesting}
                inputProps={{ helperText: "Enter address, city or zipcode" }}
              />
              <div
                className={`error-message
              ${error?.location || formState.placeId?.error ? "opacity-1" : ""}`}
              >
                {error?.location || formState.placeId?.error}
              </div>
              <div className="select-row">
                <div className="select-wrapper">
                  <USelect
                    placeholderLabel="Owner"
                    options={assigneesOptions}
                    value={formState.owner.value}
                    inputError={error?.owner || formState.owner.error}
                    className={
                      error?.owner || formState.owner.error
                        ? "error-field-wrapper"
                        : ""
                    }
                    onChange={handleDropdownChange("owner")}
                    onFocus={clearError}
                    disabled={isRecruiter(userType)}
                    readOnly={isRecruiter(userType)}
                  />
                  <div
                    className={`error-message ${
                      error?.owner || formState.owner.error ? "opacity-1" : ""
                    }`}
                  >
                    {error?.owner || formState.owner.error}
                  </div>
                </div>
                <div className="select-wrapper">
                  <USelect
                    placeholderLabel="Assignee"
                    value={formState.assignees.value}
                    onChange={handleDropdownChange("assignees")}
                    options={assigneesOptions}
                    inputError={error?.assignees || formState.assignees.error}
                    className={
                      error?.assignees || formState.assignees.error
                        ? "error-field-wrapper"
                        : ""
                    }
                    onFocus={clearError}
                  />
                  <div
                    className={`error-message ${
                      error?.assignees || formState.assignees.error
                        ? "opacity-1"
                        : ""
                    }`}
                  >
                    {error?.assignees || formState.assignees.error}
                  </div>
                </div>
              </div>
              <USelect
                placeholderLabel={"Employment Type"}
                value={formState.employment_type.value}
                onChange={handleDropdownChange("employment_type")}
                options={employmentTypeList}
                inputError={
                  error?.employment_type || formState.employment_type.error
                }
                className={
                  error?.employment_type || formState.employment_type.error
                    ? "error-field-wrapper"
                    : ""
                }
                onFocus={clearError}
                getOptionLabel={(option) => employmentTypeKeyToLabel[option.key]?? ''}
              />
              <div
                className={`error-message ${
                  error?.employment_type || formState.employment_type.error
                    ? "opacity-1"
                    : ""
                }`}
              >
                {error?.employment_type || formState.employment_type.error}
              </div>
              <div className="select-row">
                <div className="select-wrapper">
                  <USelect
                    placeholderLabel={"Job Category"}
                    value={formState.job_category.value}
                    onChange={handleDropdownChange("job_category")}
                    options={jobCategoryList}
                    inputError={error?.job_category || formState.job_category.error}
                    className={
                      error?.job_category || formState.job_category.error
                        ? "error-field-wrapper"
                        : ""
                    }
                    onFocus={clearError}
                    getOptionLabel={(option) => jobCategoryListKeyToLabel[option.key]?? ''}
                  />
                  <div
                    className={`error-message ${
                      error?.job_category || formState.job_category.error
                        ? "opacity-1"
                        : ""
                    }`}
                  >
                    {error?.job_category || formState.job_category.error}
                  </div>
                </div>
                <div className="select-wrapper">
                  <USelect
                    placeholderLabel={"Experience Level"}
                    value={formState.experience_level.value}
                    onChange={handleDropdownChange("experience_level")}
                    options={experienceLevelList}
                    inputError={
                      error?.experience_level || formState.experience_level.error
                    }
                    className={
                      error?.experience_level || formState.experience_level.password
                        ? "error-field-wrapper"
                        : ""
                    }
                    onFocus={clearError}
                  />
                  <div
                    className={`error-message ${
                      error?.experience_level || formState.experience_level.error
                        ? "opacity-1"
                        : ""
                    }`}
                  >
                    {error?.experience_level || formState.experience_level.error}
                  </div>
                </div>
              </div>
              <div className="radio-input-wrapper">
                <label className="field-label">Employment Mode</label>
                <Stack
                  direction="row"
                  flexWrap={"wrap"}
                  spacing={1}
                  overflow="auto"
                  useFlexGap
                >
                  <URadio
                    label="Remote"
                    value="Remote"
                    name="job-type"
                    onChange={handleInputChange("job_type")}
                    selectedValue={formState?.job_type?.value}
                    error={error?.job_type || formState?.job_type?.error}
                  />
                  <URadio
                    label="Office"
                    value="Office"
                    name="job-type"
                    onChange={handleInputChange("job_type")}
                    selectedValue={formState?.job_type?.value}
                    error={error?.job_type || formState?.job_type?.error}
                  />
                  <URadio
                    label="Hybrid"
                    value="Hybrid"
                    name="job-type"
                    onChange={handleInputChange("job_type")}
                    selectedValue={formState?.job_type?.value}
                    error={error?.job_type || formState?.job_type?.error}
                  />
                </Stack>
              </div>
              <div
                className={`error-message ${
                  error?.job_type || formState?.job_type?.error ? "opacity-1" : ""
                }`}
              >
                {error?.job_type || formState.job_type.error}
              </div>
              <div className="fill-wrapper">
                <label className="field-label">Fillability</label>
                <Stack
                  direction="row"
                  flexWrap={"wrap"}
                  spacing={1}
                  overflow="auto"
                  useFlexGap
                >
                  {fillabilityLevelList?.map(item => (
                    <Chip
                      key={item?.key}
                      label={`Level ${item.label}`}
                      onClick={handleFillabilityChange(
                        "fillability",
                        `Level ${item.key}`
                      )}
                      className={`${
                        formState.fillability.value === `Level ${item.key}`
                          ? "active-chip"
                          : error?.fillability || formState?.fillability?.error
                          ? "error-chip"
                          : ""
                      }`}
                    />
                  ))}
                </Stack>
              </div>
              <div
                className={`error-message ${
                  error?.fillability || formState?.fillability?.error
                    ? "opacity-1"
                    : ""
                }`}
              >
                {error?.fillability || formState.fillability.error}
              </div>
            </div>
            <div className="compensation-section">
              <label className="section-header">Compensation</label>
              <div className="input-row">
                <div className="input-wrapper">
                  <UInput
                    placeholder="Min Compensation"
                    value={formState.minimum_compensation.value}
                    id={
                      error?.minimum_compensation
                        ? "outlined-error-helper-text"
                        : ""
                    }
                    errorClass={
                      error?.min_compensation ||
                      formState.minimum_compensation.error
                        ? "opacity-1"
                        : ""
                    }
                    errorText={
                      error?.min_compensation ||
                      formState.minimum_compensation.error
                    }
                    inputError={
                      error?.minimum_compensation ||
                      formState.minimum_compensation.error
                    }
                    onChange={handleInputChange("minimum_compensation")}
                    onFocus={clearError}
                  />
                </div>
                <div className="input-wrapper">
                  <UInput
                    placeholder="Max Compensation"
                    value={formState.maximum_compensation.value}
                    id={
                      error?.max_compensation
                        ? "outlined-error-helper-text"
                        : ""
                    }
                    errorClass={
                      error?.max_compensation ||
                      formState.maximum_compensation.error
                        ? "opacity-1"
                        : ""
                    }
                    errorText={
                      error?.max_compensation ||
                      formState.maximum_compensation.error
                    }
                    inputError={
                      error?.maximum_compensation ||
                      formState.maximum_compensation.error
                    }
                    onChange={handleInputChange("maximum_compensation")}
                    onFocus={clearError}
                  />
                </div>
              </div>
              <div className="select-row">
                <div className="select-wrapper">
                  <USelect
                    placeholderLabel={"Compensation Period"}
                    value={formState.compensation_period.value}
                    onChange={handleDropdownChange("compensation_period")}
                    options={compensationPeriodList}
                    className={
                      error?.compensation_period ||
                      formState.compensation_period.error
                        ? "error-field-wrapper"
                        : ""
                    }
                    onFocus={clearError}
                    inputError={
                      error?.compensation_period ||
                      formState.compensation_period.error
                    }
                  />
                  <div
                    className={`error-message ${
                      error?.compensation_period ||
                      formState.compensation_period.error
                        ? "opacity-1"
                        : ""
                    }`}
                  >
                    {error?.compensation_period ||
                      formState.compensation_period.error}
                  </div>
                </div>
                <div className="select-wrapper">
                  <USelect
                    placeholderLabel={"Currency"}
                    value={formState.currency.value}
                    onChange={handleDropdownChange("currency")}
                    className={
                      error?.currency || formState.currency.password
                        ? "error-field-wrapper"
                        : ""
                    }
                    options={currencyList}
                    onFocus={clearError}
                    inputError={error?.currency || formState.currency.error}
                  />
                  <div
                    className={`error-message ${
                      error?.currency || formState.currency.error ? "opacity-1" : ""
                    }`}
                  >
                    {error?.currency || formState.currency.error}
                  </div>
                </div>
              </div>
            </div>
            <div className="skill-wrapper">
              <label className="section-header">Skills</label>
              <SkillCard
                isEdited={true}
                getSkillInfo={formState.skills.value}
                isCandidate={true}
                onClick={handleAddSkillClick}
                loading={skillsScoreRequesting}
              />
              <div
                className={`error-message ${
                  error?.skills || formState.skills.error ? "opacity-1" : ""
                }`}
              >
                {error?.skills || formState.skills.error}
              </div>
            </div>
          </div>
          <div className="bottom-button-wrapper flex-justify-center">
            <Button
              title="Cancel"
              className="action-btn transparent-outline-btn"
              onClick={goToMyJobs}
            />
            {/* <Button
              title="Save as draft"
              className="action-btn transparent-outline-btn"
              showSpinner={requesting === "draft"}
              onClick={onSubmit("draft")}
            /> */}
            <Button
              title={isEditing() ? "Update Job" : "Post Job"}
              className={`action-btn`}
              onClick={onSubmit("active")}
              showSpinner={requesting === "active"}
            />
          </div>
        </div>
      </div>
      <EditModal
        setShowModal={setShow}
        showModal={show}
        currentModal={currentModal?.section}
        selectedId={currentModal?.id}
        handleOnSubmit={handleSkillsChange}
        editData={formState?.skills?.value}
      />
    </>
  )
}

const mapStateToProps = state => ({
  postCreateRoleInfo: state.createRole.postCreateRoleInfo,
  requesting: state.createRole.requesting,
  error: state.createRole.error,
  getJobInfo: state.createRole.getJobInfo,
  profileSkillData: state.profile.profileSkillData,
  assigneesList: state.createRole.assignees,
  loginInfo: state.login.loginInfo,
  userType: state.login.userType,
  skillsScore: state.createRole.skillsScore,
  skillsScoreRequesting: state.createRole.skillsScoreRequesting
})

const mapDispatchToProps = dispatch => ({
  postCreateNewRoleDispatch: (data, history) =>
    dispatch(postCreateNewRole(data, history)),
  postCreateNewRoleFailureDispatch: data =>
    dispatch(postCreateNewRoleFailure(data)),
  getAssigneesDispatch: data => dispatch(getAssigneesRequest(data)),
  getSkillsScore: data => dispatch(getSkillsScoreRequest(data)),
  clearSkillScore: data => dispatch(getSkillsScoreSuccess([]))
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateRole)
