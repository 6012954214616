import React, { useEffect, useCallback } from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"

import PasswordInput from "../../../components/PasswordInput"
import UInput from "../../../components/UInput"
import UCheckbox from "../../../components/UCheckbox"
import Button from "../../../components/Button"
import SocialLogin from "../../../components/SocialLogin"
import { useForm } from "../../../hooks"
import {
  googleLoginRequest,
  googleLoginRequestFailure,
  microsoftLoginRequest,
  microsoftLoginRequestFailure,
  loginRequest,
  loginRequestFailure
} from "./redux/action"

const Login = props => {
  const {
    loginRequest,
    error,
    requesting,
    loginRequestFailure,
    loginRequestGoogle,
    loginRequestGoogleFailure,
    loginRequestMicrosoft,
    loginRequestMicrosoftFailure,
    setEmail
  } = props
  const history = useHistory()

  useEffect(() => {
    loginRequestFailure(false)
  }, [])

  const stateSchema = {
    email: {
      value: "",
      error: ""
    },
    password: {
      value: "",
      error: ""
    }
  }

  const validationStateSchema = {
    email: {
      required: true
    },
    password: {
      required: true
    }
  }

  const { state, handleOnChange } = useForm(stateSchema, validationStateSchema)

  const onLoginError = useCallback(
    (provider, error) => {},
    [loginRequestGoogleFailure, loginRequestMicrosoftFailure]
  )

  const onLoginSuccess = useCallback(
    (provider, userData) => {
      provider === "google" && loginRequestGoogle(userData)
      provider === "microsoft" && loginRequestMicrosoft(userData)
    },
    [localStorage, loginRequestGoogle, loginRequestMicrosoft]
  )

  const onSubmit = () => {
    const data = {
      username: state.email.value.trim(),
      password: state.password.value,
      user_type: "candidate"
    }
    loginRequest(data)
  }

  const clearErrors = () => {
    loginRequestFailure(false)
  }
  const goToSignup = () => history.push("/auth/signup")

  const goToForgotPassword = () => history.push("/auth/forgot_password")

  const handleInputChange = field => val => {
    handleOnChange(field, val.target.value.trim())
    field === "email" && setEmail(val.target.value?.trim())
  }

  const rememberUser = e => {
    localStorage.setItem("rememberMe", e.target.checked)
  }

  return (
    <div className="auth-form-wrapper column-flex right-inside">
      <div className="first-wrapper">
        <div className="auth-welcome-text">
          <h1>Welcome back!</h1>
          <p>Sign in to your account</p>
        </div>
      </div>
      <div className="second-wrapper">
        <UInput
          placeholder="Email Address"
          id={error ? "outlined-error-helper-text" : ""}
          value={state.email.value}
          onChange={handleInputChange("email")}
          onFocus={clearErrors}
          inputError={error?.username}
          errorText={error?.username || state.email.error}
          errorClass={error?.username || state.email.error ? "opacity-1" : ""}
        />
        <PasswordInput
          focusedLabel="Enter Password"
          placeholder="Enter password"
          value={state.password.value}
          onChange={handleInputChange("password")}
          onFocus={clearErrors}
          className={
            error?.password && error.password ? "error-field-wrapper" : ""
          }
        />
        <div
          className={`error-message ${
            error || state.password.error ? "opacity-1" : ""
          }`}
        >
          {error?.password || state.password.error || error}
        </div>
        <div className="form-input-inner-inputs">
          <UCheckbox label="Remember Me" onChange={rememberUser} />
          <div className="forgot-password" onClick={goToForgotPassword}>
            Forgot Password?
          </div>
        </div>
        <Button
          title="Sign In "
          onClick={onSubmit}
          className={
            `${state?.email?.value?.length && state.password.value?.length
              ? "full-width"
              : "disabled-btn full-width"} sign-in-button`
          }
          showSpinner={requesting}
        />
        <div className="signup">
          <span>Don't have an account?</span>&nbsp;
          <span className="signup-txt" onClick={goToSignup}>
            Sign Up
          </span>
        </div>
        <div className="other-sign-in-options">
            <SocialLogin
              className="sign-in-options"
              onLoginSuccess={onLoginSuccess}
              onLoginError={onLoginError}
              pretext="Sign In"
              userType="recruiter"
            />
          </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  loginInfo: state.login.loginInfo,
  error: state.login.error,
  requesting: state.login.requesting,
  currentUserInfo: state.login.currentUserInfo
})

const mapDispatchToProps = dispatch => ({
  loginRequest: data => dispatch(loginRequest(data)),
  loginRequestFailure: data => dispatch(loginRequestFailure(data)),
  loginRequestGoogle: data => dispatch(googleLoginRequest(data)),
  loginRequestMicrosoft: data => dispatch(microsoftLoginRequest(data)),
  loginRequestGoogleFailure: data => dispatch(googleLoginRequestFailure(data)),
  loginRequestMicrosoftFailure: data =>
    dispatch(microsoftLoginRequestFailure(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
