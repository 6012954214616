import { connect } from "react-redux"
import React, { useEffect } from "react"
import Stack from "@mui/material/Stack"

import { Images } from "../../../../theme/Images"
import Button from "../../../Button"
import Image from "../../../Image"
import USelect from "../../../USelect"

import {
  profileCertificationFailure,
  profileCertificationRequest
} from "../../../../pages/CandidateScreens/Profile/redux/action"
import { useMetaData, useForm } from "../../../../hooks"

const ProfileCertificate = props => {
  const {
    profileCertificationRequest,
    profileCertificationFailure,
    requesting,
    BeError,
    editProfileCertificate
  } = props
  const { certificatesList } = useMetaData()

  const selectedCertificates =
    editProfileCertificate?.map(({ certification_name }) => ({
      label: certification_name?.title,
      key: certification_name?.acronym,
      data: certification_name
    })) || []

  const stateSchema = {
    certificationName: {
      value: selectedCertificates,
      error: ""
    }
  }

  const validationStateSchema = {
    certificationName: {
      required: false
    }
  }
  const { state, handleOnChange, disable } = useForm(
    stateSchema,
    validationStateSchema
  )

  const handleCertificateSelect = (e, value) => {
    if (!value) return false

    if (
      state?.certificationName?.value?.find(
        item => item?.key?.toLowerCase() === value?.key?.toLowerCase()
      )
    ) {
      return false
    }

    const selected = state?.certificationName?.value?.length
      ? [...state?.certificationName?.value, value]
      : [value]

    handleOnChange("certificationName", selected)
  }

  const Submit = () => {
    const certifications = state.certificationName.value?.map(
      item => item?.data
    )
    const data = {
      certification_name: certifications
    }

    profileCertificationRequest(data)
  }

  useEffect(() => {
    profileCertificationFailure(false)
  }, [profileCertificationFailure])

  const handleDelete = (val) => {
    const selectedCertificates = state.certificationName.value?.filter(
      item => item?.key !== val?.key
    )
    handleOnChange(
      "certificationName",
      selectedCertificates?.length > 0 ? selectedCertificates : null
    )
  }

  return (
    <div className="profile-body-input-wrapper profile-info-input-wrapper">
      <div className="input-wrapper">
        <div className="info-text">
          Please include any certifications you have achieved.
        </div>
        <USelect
          placeholderLabel={"Select a Certification"}
          options={certificatesList}
          onChange={handleCertificateSelect}
        />
        <div
          className={`error-message
              ${
                BeError?.certification_name && state?.certificationName?.error
                  ? "opacity-1"
                  : ""
              }`}
        >
          {BeError?.certification_name || state?.certificationName?.error}
        </div>
        <Stack direction="row" flexWrap={"wrap"} spacing={1} overflow="auto">
          {state.certificationName.value?.length > 0 &&
            state.certificationName.value.map((option, index) => (
              <CertificateTag
                option={option}
                index={index}
                handleDelete={handleDelete}
                key={`certificate-tag-${option?.label}-${index}`}
              ></CertificateTag>
            ))}
        </Stack>
      </div>
      <div className="button-wrapper">
        <Button
          className={`${disable ? "disabled-btn" : ""}`}
          title="Save"
          onClick={Submit}
          showSpinner={requesting}
          disabled={disable ? true : false}
        />
      </div>
    </div>
  )
}


const CertificateTag = props => {
  const { option, index, handleDelete } = props
  const handleCertificateDelete = () => {
    handleDelete(option)
  }
  return (
    <div
      className="chip-tag flex-justify-center"
      title={option?.label?.length > 40 ? option?.label : ""}
    >
      <div className="chip-tag__title">{option?.label}</div>
      <div className="chip-tag__edit-img flex-justify-center">
        <Image
          src={Images.bgDelIcon}
          onClick={handleCertificateDelete}
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  requesting: state.profile.requesting,
  BeError: state.profile.error
})

const mapDispatchToProps = dispatch => ({
  profileCertificationRequest: data =>
    dispatch(profileCertificationRequest(data)),
  profileCertificationFailure: data =>
    dispatch(profileCertificationFailure(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCertificate)
