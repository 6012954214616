import React, { useState } from 'react'

// Components
import UInput from '../../../UInput'
import Button from '../../../Button'

const SendFeedback = () => {

    const [feedback, setFeedback] = useState(false)

    return (
        <div className="profile-body-input-wrapper feedback-input-wrapper">
            <div className="input-wrapper">
                {feedback ?
                    <div className="feedback-message">
                        Thank you for your feedback. We ar continuously improving our system and your feedback is precious for us.
                    </div>
                    :
                    <UInput
                        placeholder='Message'
                        errorText={''}
                        inputError={''}
                        errorClass={''}
                        id='outlined-multiline-static'
                        minRows={1}
                        multiline
                        maxRows={6}
                        className="mui-text-area"
                    />
                }
            </div>
            <div className="button-wrapper">
                {feedback ?
                    <Button
                        title='Close'
                        className='transparent-btn'
                    />
                    :
                    <Button
                        title='Send'
                        className='purple-btn'
                    />
                }
            </div>
        </div>
    )
}

export default SendFeedback