import React, { useCallback } from "react"
import DataTable from "../../../components/DataTable"
import dayjs from "dayjs"
import { getJobsAppliedRequest } from "./redux/action"
import { connect } from "react-redux"
import { isArray } from "../../../utils/commonFunction"

const JobsApplied = ({
  candidateJobsApplied,
  requestingJobs,
  candidateData,
  getAppliedJobsDispatch
}) => {
  const getAppliedJobs = useCallback(
    ({ page, limit, sortBy, sortDir }) => {
      getAppliedJobsDispatch({
        page,
        limit,
        sortBy,
        sortDir,
        candidateId: candidateData?.candidate_id
      })
    },
    [candidateData, getAppliedJobsDispatch]
  )

  const columns = [
    {
      id: "job_title",
      label: "Job Applied",
      width: "30%",
      sortable: true,
      renderColumn: rowData => {
        return <div className="job-applied">{rowData["job_title"]}</div>
      }
    },
    {
      id: "created_at",
      label: "Application Date",
      width: "30%",
      sortable: true,
      renderColumn: rowData => {
        return (
          rowData["created_at"] &&
          dayjs(rowData["created_at"]).format("DD/MM/YYYY")
        )
      }
    },
    {
      id: "application_status",
      label: "Application Status",
      width: "30%",
      renderColumn: rowData => {
        return (
          <div className="application-status">
            <span className="status">
              {rowData["application_status"] || "Applied"}
            </span>
            <span className="date">
              {rowData["updated_at"] ? (
                <>
                  &nbsp;-&nbsp;
                  {dayjs(rowData["updated_at"]).format("DD/MM/YYYY")}
                </>
              ) : (
                rowData["created_at"] && (
                  <>
                    &nbsp;-&nbsp;
                    {dayjs(rowData["created_at"]).format("DD/MM/YYYY")}
                  </>
                )
              )}
            </span>
          </div>
        )
      }
    }
  ]
  return (
    <DataTable
      columns={columns}
      data={
        isArray(candidateJobsApplied)
          ? candidateJobsApplied
          : candidateJobsApplied?.data
      }
      totalPages={candidateJobsApplied?.page?.total_page || 1}
      totalRows={
        isArray(candidateJobsApplied) ? candidateJobsApplied?.length : 0
      }
      getTableData={getAppliedJobs}
      loading={requestingJobs}
      key="applied_jobs_table"
    />
  )
}

const mapStateToProps = state => ({
  candidateJobsApplied: state.selectedProfile.candidateJobsApplied,
  requestingJobs: state.selectedProfile.requestingJobs
})

const mapDispatchToProps = dispatch => ({
  getAppliedJobsDispatch: data => dispatch(getJobsAppliedRequest(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(JobsApplied)
